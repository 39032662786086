import React, { useState } from "react";

const TableItem = ({ spamItem, }) => {
    const { id, site, ip, phone, lead, createdAt } = spamItem

    return (<React.Fragment>
        <tr key={id} className="odd">
            <td><a target="_blank"  >{phone}</a></td>
            <td>{site}</td>
            <td>{ip}</td>
            <td>{createdAt}</td>
        </tr>
    </React.Fragment>

    )
}

export default TableItem