import PageTitle from "../../page-title"
import { compose } from "../../../utils";
import { withApiService } from "../../hoc";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom'
import { Component, useEffect, useState } from "react";
import { fetchSpamList } from "../../../actions/spam";
import { Spinner } from "../../spinner";
import axios from "axios";
import ApiService from "../../../services/api-service";
import { toast } from "react-toastify";
import TableItem from "./table-item/table-item";

const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const dateNowFormatted = (add) => {
    let d = new Date()
    d.setDate(d.getDate() + add);
    let month = (d.getMonth() + 1)
    let day = d.getDate().toString()
    let year = d.getFullYear().toString()

    if (month.toString().length < 2)
        month = '0' + month.toString();
    if (day.toString().length < 2)
        day = '0' + day.toString();

    console.log([year, month, day].join('-'))

    return [year, month, day].join('-');
}

const SpamPage = ({ spam, loading, fetchSpamList }) => {
    const [dateStart, setDateStart] = useState("1990-20-11")
    const [dateEnd, setDateEnd] = useState("1990-20-11")

    const dateChange = (event) => {
        if (event.target.name === "start") {
            setDateStart(event.target.value)
        } else {
            setDateEnd(event.target.value)
        }
    }

    const update = (e) => {
        e.preventDefault()
        fetchSpamList(dateStart, dateEnd)
    }

    const generateReport = () => {
        let data = {
            startDate: dateStart,
            endDate: dateEnd,
        }

        fetch('/api/spam/excel', { method: 'POST', body: JSON.stringify(data) })
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                let filename = "report.xlsx";
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename; 
                document.body.appendChild(link); 
                link.click(); 
                document.body.removeChild(link);
            });
    }

    useEffect(() => {
        let dateNow = new Date()
        setDateStart(dateNow.toISOString().split('T')[0])
        dateNow.setDate(dateNow.getDate() + 1)
        setDateEnd(dateNow.toISOString().split('T')[0])
    }, [])

    return (<div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle title={"Детальная информация по домену"} />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="invoice-title">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <div className="mb-4">
                                                <span className="logo-txt">Цены в слайдерах по городам</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-sm-12 col-md-2">
                                            <label>Дата ОТ:</label><input onChange={dateChange} type="date" className="form-control" name={"start"} placeholder="" value={dateStart} aria-controls="DataTables_Table_1" />
                                        </div>
                                        <div className="col-sm-12 col-md-2">
                                            <label>Дата ДО:</label><input onChange={dateChange} type="date" className="form-control" name={"end"} placeholder="" value={dateEnd} aria-controls="DataTables_Table_2" />
                                        </div>
                                        <div className="col-sm-12 col-md-2" style={{ display: "flex", alignItems: "end" }}>
                                            <div className="">
                                                <a href="#" onClick={update} className="btn btn-primary w-md waves-effect waves-light">Обновить</a>
                                                <a href="#" onClick={()=>{generateReport()}} style={{marginLeft:"10px"}} className="btn btn-primary w-md waves-effect waves-light">Excel</a>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table className="table align-middle datatable dt-responsive table-check nowrap dataTable no-footer table-striped" style={{ borderCollapse: "collapse", borderSpacing: "0px 8px", width: "100%" }} >
                                                <thead>
                                                    <tr className="bg-transparent" role="row">
                                                        <th style={{ width: "80px" }} className="sorting" >Номер телефона</th>
                                                        <th style={{ width: "80px" }} className="sorting" >Сайт</th>
                                                        <th style={{ width: "80px" }} className="sorting" >ip</th>
                                                        <th style={{ width: "80px" }} className="sorting" >Дата</th>
                                                    </tr>
                                                </thead>
                                                {spam == null ? <tbody></tbody> : <tbody>
                                                    {
                                                        spam.map((spamItem) => {
                                                            return <TableItem spamItem={spamItem} />
                                                        })
                                                    }
                                                </tbody>}
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                <hr className="my-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}


class SpamPageContainer extends Component {

    componentDidMount() {
        this.props.fetchSpamList(dateNowFormatted(0), dateNowFormatted(1))
    }

    render() {
        const { spam, loading, error, fetchSpamList } = this.props;

        if (loading || spam == null) {
            return <Spinner />
        }

        return <SpamPage spam={spam} error={error} fetchSpamList={fetchSpamList} />;
    }
}

const mapStateToProps = ({ spamList: { spam, loading, error } }) => {
    return { spam, loading, error };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return bindActionCreators({
        fetchSpamList: fetchSpamList(apiService)
    }, dispatch);
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(SpamPageContainer);