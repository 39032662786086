import axios from "axios"
import { useState } from "react"
import PageTitle from "../../page-title"

export const SpamValidatePage = () => {
    const [numbers, setNumbers] = useState("")
    const [status, setStatus] = useState()
    const [spamList, setSpam] = useState([])

    const checkPhoneNumbers = async () => {
        setStatus("Проверка...")
        let numbersList = numbers.split('\n')
        let resp = await axios.post('/api/lead/spamcheck/', { "phoneList": numbersList })
        if (resp.data) {
            setSpam(resp.data)
        } else {
            setStatus("Ошибка!")
            return
        }

        setStatus("Проверка завершена!")
    }

    const inputChange = (e) => {
        setNumbers(e.target.value)
    }

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <PageTitle title={"Валидация списка номеров"} />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label htmlFor="basicpill-servicetax-input" className="form-label">Список номеров (пример номера 79182791867)</label>
                                                <textarea type="text" className="form-control" name="numbers" value={numbers} onChange={inputChange} style={{ minHeight: "200px" }}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-primary" onClick={checkPhoneNumbers}>Проверить номера</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {<div className="col-lg-12" style={{ marginBottom: "10px" }}>
                            <h4 class="form-check-label" for="flexSwitchCheckDefault" style={{ marginBottom: "10px" }}>{status}</h4>
                            <h5 class="form-check-label" for="flexSwitchCheckDefault" style={{ marginBottom: "10px" }}>Результат</h5>
                            <div class="row">
                                <div className="col-sm-12">
                                    <table className="table align-middle datatable dt-responsive table-check nowrap dataTable no-footer table-striped" style={{ borderCollapse: "collapse", borderSpacing: "0px 8px", width: "100%" }} >
                                        <thead>
                                            <tr className="bg-transparent" role="row">
                                                <th style={{ width: "80px" }} className="sorting" >Номер телефона</th>
                                                <th style={{ width: "80px" }} className="sorting" >Кол-во лидов с таким номером</th>
                                                <th style={{ width: "80px" }} className="sorting" >ClientID</th>
                                                <th style={{ width: "80px" }} className="sorting" >Ip</th>

                                                <th style={{ width: "80px" }} className="sorting" >Лидов с таким ClienID</th>
                                                <th style={{ width: "80px" }} className="sorting" >Спама с таким ClienID</th>
                                                <th style={{ width: "80px" }} className="sorting" >Лидов с таким IP</th>
                                                <th style={{ width: "80px" }} className="sorting" >Спама с таким IP</th>
                                            </tr>
                                        </thead>
                                        {spamList == null ? <tbody></tbody> : <tbody>
                                            {
                                                spamList.map((spam) => {
                                                    return <tr>
                                                        <td>{spam.phone}</td>
                                                        <td>{spam.repeatnes}</td>
                                                        <td>{spam.clientId}</td>
                                                        <td>{spam.ip}</td>
                                                        <td>{spam.sameClientIDCount}</td>
                                                        <td>{spam.sameClientIDInSpamList}</td>
                                                        <td>{spam.sameIpCount}</td>
                                                        <td>{spam.sameIpCountInSpamList}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>}
                                    </table>
                                </div>
                                {/*<div className="col-lg-6">
                                    <h7 style={{ fontWeight: "bold" }}>Валидные номера</h7>
                                    <div className="result" style={{marginTop: "10px", display:"flex", flexDirection:"column"}}>
                                        {validNumbers.map((number)=>{
                                            return <span>{number}</span>
                                        })}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h7 style={{ fontWeight: "bold" }}>Не валидные номера</h7>
                                    <div className="result" style={{marginTop: "10px", display:"flex", flexDirection:"column"}}>
                                        {invalidNumbers.map((number)=>{
                                            return <span>{number}</span>
                                        })}
                                    </div>
                                </div>*/}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}