import axios from "axios"
import React, { useEffect, useState } from "react"
import { QuestionType } from "../../enums"
import { QuizStep } from "./components/quizStep"

const StepsComponent = ({quizSteps, setQuizSteps, city_id}) => {
    const [raions, setRaions] = useState([])
    const inputChange = (event) => {
    }

    const stepDelete = (stepID, answerID) => {
        setQuizSteps((prevState) => {
            let newQuizSteps = Array.from(prevState)
            newQuizSteps[stepID].answers.splice(answerID, 1)
            return newQuizSteps
        })
    }

    const addStep = () => {
        setQuizSteps((prevState) => {
            let newQuizSteps = Array.from(prevState)
            newQuizSteps.push({
                title: "",
                questionType: QuestionType.SimpleQuestion,
                answers: []})
            
            return newQuizSteps
        })
    }

    const updateQuizStep = (index, step) => {
        setQuizSteps((prevState) => {
            let newQuizSteps = Array.from(prevState)
            newQuizSteps[index] = step
            return newQuizSteps
        })
    }

    const getCityRaions = () => {
        if (city_id !== undefined){
            axios.get("/api/cities/raions/"+city_id).then((resp) => {
                setRaions(resp.data)
            })
        }
    }

    const questionDelete = (index) => {
        setQuizSteps((prevState) => {
            let newQuizSteps = Array.from(prevState)
            newQuizSteps.splice(index, 1)
            return newQuizSteps
        })
    } 

    useEffect(()=>{
        getCityRaions()
    },[city_id])
    

    return (<React.Fragment >
        <div className="text-center mb-4">
            <h5>Шаги</h5>
            <p className="card-title-desc">Заполните шаги (если ни один шаг не будет добавлен, то будут использованны, стандратные)</p>
        </div>
        
            {
                quizSteps && quizSteps.map((step, i) => {
                    return <QuizStep step={step} index={i} length={quizSteps.length} raions={raions} deleteStep={questionDelete} updateQuizStep={updateQuizStep}/>
                })
            }
        <div className="d-flex justify-content-center">
            <button className="btn btn-primary" onClick={addStep}><i className="feather-plus"></i> Добавить шаг </button>
        </div>
    </React.Fragment>)
}

export default StepsComponent