import axios from "axios"
import { useEffect, useState } from "react"

export const PriceType = ({ index, updateFilter, cityId, param }) => {
    const [price, setPrice] = useState([0,0])

    const updateParam = (value, min) => {
        let newPrices = price
        if (min) {
            newPrices[0] = parseInt(value)
        } else {
            newPrices[1] = parseInt(value)
        }

        setPrice([...newPrices])
    }

    useEffect(()=>{
        if (param.filter.prices) {
            setPrice(param.filter.prices)
        }
    },[])

    useEffect(() => {
        updateFilter(price, "prices")
    }, [price])



    return (
        <div key={index} className="row mb-3">
            <div className="col-lg-6 ml-2">
                <div className="row">
                    <div className="col-lg-6 ml-2">
                        <div className="mb-3" >
                            <label htmlFor="basicpill-lastname-input" className="form-label">От</label>
                            <input style={{ marginLeft: "10px", marginRight: "10px" }} type="number" autoComplete="off" onChange={(e) => { updateParam(e.target.value, true) }} className="form-control" name="mail" id="basicpill-servicetax-input" value={price[0]} />
                        </div>
                    </div>
                    <div className="col-lg-6 ml-2">
                        <div className="mb-3" >
                            <label htmlFor="basicpill-lastname-input" className="form-label">До (поставить 0, если не нужно)</label>
                            <input style={{ marginLeft: "10px", marginRight: "10px" }} type="number" autoComplete="off" onChange={(e) => { updateParam(e.target.value, false) }} className="form-control" name="mail" id="basicpill-servicetax-input" value={price[1]} />
                        </div>
                    </div>
                </div>
                </div>
            </div>
            )
}