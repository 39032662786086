const testingsRequested = () => {
    return {
        type: 'TESTINGS_REQUESTED',
    }
}

const testingsLoaded = (domains) => {
    return {
        type: 'TESTINGS_FETCH_SUCCESS',
        payload: domains
    }
}

const testingsError = (data) => {
    return {
        type: 'TESTINGS_ERROR',
        payload: data
    }
}

const deleteTestingAction = (id) => {
    return {
        type: 'TESTING_DELETE_ONE',
        payload: id
    }
}

const updateTestingAction = (data) => {
    return {
        type: 'TESTING_UPDATE',
        payload: data,
    }
}

const fetchTestings = (apiService) => () => (dispatch) => {
    dispatch(testingsRequested)
    apiService.testingsList()
        .then((response) => dispatch(testingsLoaded(response.data)))
        .catch((err) => dispatch(testingsError(err)))
}

const deleteTesting = (apiService) => (id) => (dispatch) => {
    apiService.deleteUser(id)
        .then((response) => dispatch(deleteTestingAction(id)))
        .catch((err) => dispatch(testingsError(err)))
}

const updateTesting = (apiService) => (data) => (dispatch) => {
    apiService.updateUser(data)
        .then((response) => dispatch(updateTestingAction(response.data)))
        .catch((err) => dispatch(testingsError(err)))
}

export {
    fetchTestings,
    deleteTesting,
    updateTesting,
    deleteTestingAction,
    updateTestingAction,
}