import PageTitle from "../../../page-title"
import TableItem from "./table-item"
import { compose } from "../../../../utils";
import { withApiService } from "../../../hoc";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Component, useEffect, useRef, useState } from "react";
import { fetchTestings, } from "../../../../actions/testings";
import { Spinner } from "../../../spinner";
import { useNavigate } from "react-router-dom"
import ApiService from "../../../../services/api-service";
import { toast } from "react-toastify";
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { Modals, showModal } from "../../../modals";

const TestingPage = ({ testings, loading, fetchTestings }) => {
    const [load, setLoad] = useState(false)
    const [domainsList, setDomainsList] = useState()


    const navigate = useNavigate();

    const inputChange = (event) => {
        
    }
    useEffect(() => {
        if (testings == null) {
            fetchTestings()
        }

        let apiService = new ApiService
        if (!domainsList) {
            apiService.domainsList("", "", 9999999, "").then((response) => {
                let domainsList = []
                response.data.domains.sort((a, b) => (a.url > b.url) ? 1 : -1).forEach(domain => {
                    domainsList.push({
                        name: domain.url,
                        value: domain.url
                    })
                });
                setDomainsList(domainsList)
            })
        }
    }, [])

    const forms = [
        {
            title: 'Добавить тестирование',
            name: 'addTesting',
            url: "/api/testing/",
            type: "put",
            action: (action) => {fetchTestings()},
            fields: [
                {
                    name: 'Первый домен',
                    json: 'first_domain',
                    type: 'select',
                    options: domainsList?domainsList:[],
                },
                {
                    name: 'Второй домен',
                    json: 'second_domain',
                    type: 'select',
                    options: domainsList?domainsList:[],
                },
                {
                    name: 'Тайтл второго домена (если не нужно заменять - оставить пустым)',
                    json: 'second_domain_title',
                    type: 'text',
                },
            ],
        },
    ]
    return (<div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle title={"Список пользователей"} />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="" style={{ display: "flex", justifyContent: "end" }}>
                                    <div className="mb-4">
                                        <button onClick={() => { showModal('addTesting') }} type="button" className="btn btn-primary waves-effect waves-light"><i className="feather-plus"></i>Добавить тестирование</button>
                                    </div>
                                </div>
                                <div className="table-responsive" style={{minHeight: "100vh"}}>
                                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        
                                        <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table align-middle datatable dt-responsive table-check nowrap dataTable no-footer table-striped" style={{ borderCollapse: "collapse", borderSpacing: "0px 8px", width: "100%" }} >
                                                        <thead>
                                                            <tr className="bg-transparent" role="row">
                                                                <th style={{ width: "30px" }} className="sorting sorting_asc">
                                                                    <div className="form-check font-size-16">
                                                                        <input type="checkbox" name="check" className="form-check-input" id="checkAll" />
                                                                        <label className="form-check-label" htmlFor="checkAll"></label>
                                                                    </div>
                                                                </th>
                                                                <th style={{ width: "80px" }} className="sorting">ID</th>
                                                                <th style={{ }} className="sorting">Первый домен</th>
                                                                <th style={{ }} className="sorting">Второй домен</th>
                                                                <th style={{ width: "200px" }} className="sorting">Переходы (первый/второй)</th>
                                                                <th style={{ width: "200px" }} className="sorting" >Действия</th>
                                                            </tr>
                                                        </thead>
                                                        {testings == null ? <tbody></tbody> : <tbody>
                                                            {
                                                                testings.map((testing) => {
                                                                    return <TableItem testing={testing} fetchTesting={fetchTestings} />
                                                                })
                                                            }
                                                        </tbody>}
                                                    </table>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modals forms={forms} />
    </div>
    )
}


class TestingListPageContainer extends Component {

    componentDidMount() {
    }

    render() {
        const { testings, loading, error, fetchTestings } = this.props;
        if (loading) {
            return <Spinner />
        }
        return <TestingPage testings={testings} loading={loading} error={error} fetchTestings={fetchTestings} />;
    }
}

const mapStateToProps = ({ testingsList: { testings, loading, error } }) => {
    return { testings, loading, error };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return bindActionCreators({
        fetchTestings: fetchTestings(apiService),
    }, dispatch);
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(TestingListPageContainer);