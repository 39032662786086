const updateTestingsList = (state, action) => {

    if (state === undefined) {
        return {
            testings: null,
            loading: false,
            error: false,
        };
    }

    switch (action.type) {
        case 'TESTINGS_REQUESTED':
            return {
                testings: null,
                loading: true,
                error: false,
            };
        case 'TESTINGS_FETCH_SUCCESS':
            return {
                testings: action.payload,
                loading: false,
                error: false,
            };
        case 'TESTINGS_ERROR':
            return {
                testings: state.usersList.users,
                loading: false,
                error: true,
            };
        case 'TESTING_DELETE_ONE':
            return {
                testings: state.usersList.users,
                loading: false,
                error: true,
            };
        case 'TESTING_UPDATE':
            return {
                testings: state.usersList.users,
                loading: false,
                error: true,
            };
        default:
            return state.usersList;
    }
};

export default updateTestingsList;