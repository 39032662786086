import { useEffect, useState } from "react"
import Select from "react-select"

export const DomainJkItemSelect = ({ id, index, setSelectedJk, options }) => {
    const [selectedItem, setSelectedItem] = useState(0)

    useEffect(()=>{
        if (selectedItem !== 0) {
            setSelectedJk(selectedItem.value, index)
        }
    },[selectedItem])

    useEffect(()=>{
        if (selectedItem === 0 && id) {
            options.forEach(option => {
                console.log(id)
                if (option.value === id) {
                    setSelectedItem(option)
                }
            });
        }
    },[id, options])
   
    return <div key={"jk_list_" + index} className="row">
        <div className="col-lg-12">
            <div className="mb-3">
                <label htmlFor="basicpill-servicetax-input" className="form-label">Выберите жк</label>
                <Select key={"jk_list_select_" + index} options={options} value={selectedItem} style={{ color: "black" }} placeholder={"Выберите жк"} onChange={(val) => { setSelectedItem(val) }} name="cityId" id="multicol-cityname" />
            </div>
        </div>
    </div>


}