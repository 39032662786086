import PageTitle from "../../../page-title"
import { compose } from "../../../../utils";
import { withApiService } from "../../../hoc";
import ApiService from "../../../../services/api-service";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom'
import { Component, useEffect, useState } from "react";
import { fetchDomain } from "../../../../actions/domain-detail";
import { Spinner } from "../../../spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DomainDetail = ({ domain, loading, fetchDomain }) => {
    const { id } = useParams()
    const [sendSms, setSendSms] = useState(false)
    const [smsText, setSmsText] = useState("")
    const [extraScripts, setExtraScripts] = useState("")
    const [comment, setComment] = useState("")
    const [pdfFile, setPdfFile] = useState(false)
    const [newPdfFile, setFile] = useState()

    const [form, setForm] = useState({})
    useEffect(() => {
        fetchDomain(id)
    }, [id])
    const navigate = useNavigate()

    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(' / ')
    }

    const moderationChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            moderation: e.target.checked
        }
        domain.Moderation = e.target.checked
        axios.post("/api/domains/moderation", data).then((response) => {
            fetchDomain(id)
        })
    }

    const dynamicStylesChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            flag: e.target.checked
        }
        domain.dynamicStyles = e.target.checked
        axios.post("/api/domains/dynamic-styles", data).then((response) => {
            fetchDomain(id)
        })
    }

    const dynamicAdvantagesChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            flag: e.target.checked
        }
        domain.advantages_alt = e.target.checked
        axios.post("/api/domains/advantages-alt", data).then((response) => {
            fetchDomain(id)
        })
    }

    const qooplerChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            qoopler: e.target.checked
        }
        domain.Moderation = e.target.checked
        axios.post("/api/domains/qoopler", data).then((response) => {
            fetchDomain(id)
        })
    }

    const dumpOneChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            dmp_one: e.target.checked
        }
        domain.dmp_one = e.target.checked
        axios.post("/api/domains/dmpone", data).then((response) => {
            fetchDomain(id)
        })
    }

    const slonBizChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            slon_biz: e.target.checked
        }
        domain.dmp_one = e.target.checked
        axios.post("/api/domains/slonbiz", data).then((response) => {
            fetchDomain(id)
        })
    }

    

    const smsChange = (e) => {
        e.preventDefault()

        setTimeout(() => {
            setSendSms(!sendSms)
        }, 100)

        if (sendSms) {
            let data = {
                id: id,
                sms: !sendSms,
                sms_text: smsText,
            }
            axios.post("/api/domains/sms", data).then((response) => {
                fetchDomain(id)
            })
        }

        domain.sms = e.target.checked

    }

    const validationChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            validation: e.target.checked,
            not_send_cell_for_not_valid_phone: e.target.checked ? domain.not_send_cell_for_not_valid_phone : false
        }
        domain.validate_phone = e.target.checked
        axios.post("/api/domains/validation", data).then((response) => {
            fetchDomain(id)
        })
    }

    const dontSendCellChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            validation: domain.validate_phone,
            not_send_cell_for_not_valid_phone: e.target.checked
        }
        domain.not_send_cell_for_not_valid_phone = e.target.checked
        axios.post("/api/domains/validation", data).then((response) => {
            fetchDomain(id)
        })
    }

    const updateSmsStatus = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            sms: sendSms,
            sms_text: smsText,
        }
        domain.sms = e.target.checked
        axios.post("/api/domains/sms", data).then((response) => {
            fetchDomain(id)
        })
    }

    const sendToCcChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            flag: e.target.checked
        }
        domain.Moderation = e.target.checked
        axios.post("/api/domains/sendtocc", data).then((response) => {
            fetchDomain(id)
        })
    }

    const catalogChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            catalog: e.target.checked
        }
        domain.catalog = e.target.checked
        axios.post("/api/domains/catalog", data).then((response) => {
            fetchDomain(id)
        })
    }

    const extraScriptsChange = (e) => {
        e.preventDefault()
        let scripts = document.querySelector('[name="extraScripts"]').value
        let data = {
            id: id,
            extraScripts: scripts
        }
        domain.extraScripts = scripts
        axios.post("/api/domains/extraScripts", data).then((response) => {
            fetchDomain(id)
        })
    }

    const commentChange = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            text: comment
        }
        domain.comment = comment
        axios.post("/api/domains/comment", data).then((response) => {
            fetchDomain(id)
        })
    }

    useEffect(() => {
        if (domain != null) {
            setSendSms(domain.sms)
            setSmsText(domain.sms_text)
            setExtraScripts(domain.extraScripts)
            setComment(domain.comment)
            if (domain.leadPdfFile !== "") {
                setPdfFile(true)
            }
        }
    }, [domain])

    if (loading || domain == null) {
        return <Spinner />
    }

    const editClick = (event) => {
        event.preventDefault()
        navigate(event.target.getAttribute('href'), { replace: false })
    }

    const inputChange = (event) => {
        setSmsText(event.target.value)
    }

    const extraInput = (event) => {
        setExtraScripts(event.target.value)
    }

    const commentInput = (event) => {
        setComment(event.target.value)
    }

    const updateMask = (e) => {
        e.preventDefault()
        let data = {
            id: id,
            mask: e.target.value,
        }
        domain.Mask = e.target.value
        axios.post("/api/domains/mask", data).then((response) => {
            fetchDomain(id)
        })
    }

    const pdfFileChange = (e) => {
        let flag = e.target.checked
        setPdfFile(flag)
        if (!flag) {
            var bodyFormData = new FormData()
            bodyFormData.append('id', domain.id)
            let apiService = new ApiService

            let promise = apiService.pdfSave(bodyFormData)

            toast.promise(
                promise,
                {
                    pending: 'Сохраняю данные',
                    success: 'Pdf файл успешно удален 👌',
                    error: 'Ошибка при удалении pdf 🤯'
                }
            )
        }
    }

    const fileInputChange = (event) => {
        try {
            console.log("changed")

            if (event.target.files.length == 0) {
                return
            }
            console.log(event.target.files[0])
            var bodyFormData = new FormData()

            bodyFormData.append('file', event.target.files[0])
            bodyFormData.append('id', domain.id)

            let apiService = new ApiService

            let promise = apiService.pdfSave(bodyFormData)

            toast.promise(
                promise,
                {
                    pending: 'Сохраняю данные',
                    success: 'Pdf файл успешно сохранен 👌',
                    error: 'Ошибка при сохранении pdf 🤯'
                }
            )

            promise.then((data) => {
                window.location.reload()
            })
        } catch (e) {
            console.log(e)
        }

    }



    return (<div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle title={"Детальная информация по домену"} />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="invoice-title">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <div className="mb-4">
                                                <span className="logo-txt">{domain.url}</span>
                                            </div>
                                            <div className="row align-items-end" >
                                                    <div class="col-lg-6">
                                                        <textarea type="text" class="form-control" name="comment" id="comment" onChange={commentInput} value={comment} ></textarea>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <a href={"/"} className="btn btn-primary w-md waves-effect waves-light" onClick={commentChange}>Сохранить</a>
                                                    </div>
                                                </div>
                                        </div>
                                        
                                        <div className="flex-shrink-0">
                                            <div className="mb-4">
                                                <a href={"/edit/" + id} className="btn btn-primary w-md waves-effect waves-light" onClick={editClick}>Редактировать</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="col-lg-6" style={{ marginBottom: "10px" }}>
                                                <label htmlFor="basicpill-servicetax-input" className="form-label">Маска номера телефона</label>
                                                <select onChange={updateMask} className="form-select custom-select" id="template" name="politics" value={domain.mask}>
                                                    <option value=''>Россия  +7 (XXX) XXX-XX-XX</option>
                                                    <option value='+7\ 9999999999'>Казахстан  +7 XXXXXXXXXX</option>
                                                    <option value='+374\ 99999999'>Армения  +374 XXXXXXXX</option>
                                                </select>
                                            </div>
                                            <div className="d-flex align-items-start" style={{ marginBottom: "10px" }}>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" onChange={moderationChange} checked={domain.moderation} type="checkbox" name="moderation" key="flexSwitchCheckDefault" id="flexSwitchCheckDefault" />
                                                    <label class="form-check-label" for="flexSwitchCheckDefault">Модерация</label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start" style={{ marginBottom: "10px" }}>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" onChange={qooplerChange} checked={domain.qoopler} type="checkbox" name="moderation" key="flexSwitchCheckDefault" id="flexSwitchCheckDefault" />
                                                    <label class="form-check-label" for="flexSwitchCheckDefault">WantResult</label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start" style={{ marginBottom: "10px" }}>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" onChange={dumpOneChange} checked={domain.dmp_one} type="checkbox" name="moderation" key="dumpOneCheckDefault" id="dumpOneCheckDefault" />
                                                    <label class="form-check-label" for="dumpOneCheckDefault">DmpOne</label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start" style={{ marginBottom: "10px" }}>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" onChange={slonBizChange} checked={domain.slon_biz} type="checkbox" name="moderation" key="dumpOneCheckDefault" id="dumpOneCheckDefault" />
                                                    <label class="form-check-label" for="slonBizCheckDefault">SlonBiz</label>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start" style={{ marginBottom: "10px" }}>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" onChange={sendToCcChange} checked={domain.send_to_call_center} type="checkbox" name="send_to_call_center" key={"flexSwitchCheckDefaultKc"} id="flexSwitchCheckDefaultKc" />
                                                    <label class="form-check-label" for="flexSwitchCheckDefaultKc">Отправка на КЦ</label>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-start" style={{ marginBottom: "10px", justifyContent: "space-between" }}>
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" onChange={smsChange} checked={domain.sms} type="checkbox" name="sms" key={"flexSwitchCheckDefaultSms"} id="flexSwitchCheckDefaultSms" />
                                                    <label class="form-check-label" for="flexSwitchCheckDefaultSms">Отправка смс сообщения</label>
                                                </div>
                                            </div>


                                            {sendSms ? <div className="row" style={{ alignItems: "center" }}>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label htmlFor="basicpill-companyuin-input" className="form-label">Текст сообщения</label>
                                                        <input type="text" className="form-control" name="sms_text" onChange={inputChange} value={smsText} id="basicpill-companyuin-input" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <a href={"/"} className="btn btn-primary w-md waves-effect waves-light" onClick={updateSmsStatus}>Сохранить</a>
                                                </div>
                                            </div> : ""}


                                            <p className="mb-1"><b>Дата создания:</b> {domain.created_at}</p>
                                            <p className="mb-1"><b>Roistat:</b> {domain.Roistat ? "Да" : "Нет"}</p>
                                            <p className="mb-1"><b>Yandex:</b> {domain.yandex}</p>
                                            <p className="mb-1"><b>Google:</b> {domain.google}</p>
                                            <p className="mb-1"><b>Mail:</b> {domain.mail}</p>
                                            <p className="mb-1"><b>Facebook:</b> {domain.facebook}</p>
                                            <p className="mb-1"><b>Marquiz:</b> {domain.marquiz}</p>
                                            <p className="mb-1"><b>WantResult:</b> {domain.Qoopler ? "Да" : "Нет"}</p>
                                            <p className="mb-1"><b>Datacon:</b> {domain.datacon ? "Да" : "Нет"}</p>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card-body">
                                                <div className="d-flex align-items-start" style={{ marginBottom: "10px", justifyContent: "start" }}>
                                                    <div class="form-check form-switch" style={{ paddingRight: "20px" }}>
                                                        <input class="form-check-input" onChange={validationChange} checked={domain.validate_phone} type="checkbox" name="validate_phone" key={"flexSwitchCheckDefaultValidate"} id="flexSwitchCheckDefaultValidate" />
                                                        <label class="form-check-label" for="flexSwitchCheckDefaultValidate">Валидация номера телефона перед отправкой</label>
                                                    </div>
                                                    {domain.validate_phone ? <div class="form-check form-switch">
                                                        <input class="form-check-input" onChange={dontSendCellChange} checked={domain.not_send_cell_for_not_valid_phone} type="checkbox" name="validate_phone" key={"flexSwitchCheckDefaultValidateCell"} id="flexSwitchCheckDefaultValidateCell" />
                                                        <label class="form-check-label" for="flexSwitchCheckDefaultValidateCell">Не отрпавлять цель в метрику (для невалидных номеров)</label>
                                                    </div> : ""}
                                                </div>

                                                <div className="d-flex align-items-start" style={{ marginBottom: "10px" }}>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" onChange={catalogChange} checked={domain.catalog} type="checkbox" name="send_to_call_center" key={"flexSwitchCheckDefaultKc"} id="flexSwitchCheckDefaultKc" />
                                                        <label class="form-check-label" for="flexSwitchCheckDefaultKc">Каталог (кастомный шаблон)</label>
                                                    </div>
                                                </div>

                                                <div className="coulmn" style={{ marginBottom: "10px", justifyContent: "space-between", alignItems: "end" }}>
                                                    <div class="col-lg-6" style={{ marginBottom: "10px" }}>
                                                        <label class="form-check-label" for="extraScripts" style={{ marginBottom: "10px" }}>Доп. скрипты для тестов</label>
                                                        <textarea type="text" class="form-control" name="extraScripts" id="extraScripts" onChange={extraInput} value={extraScripts} ></textarea>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <a href={"/"} className="btn btn-primary w-md waves-effect waves-light" onClick={extraScriptsChange}>Сохранить</a>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-start" style={{ marginBottom: "10px", justifyContent: "space-between" }}>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" onChange={pdfFileChange} checked={pdfFile} type="checkbox" name="pdfFileLead" key={"flexSwitchCheckDefaultSms"} id="flexSwitchCheckDefaultSms" />
                                                        <label class="form-check-label" for="flexSwitchCheckDefaultSms">Возвращать Pdf файл, если оставили заявку</label>
                                                    </div>
                                                </div>

                                                {pdfFile ? <div className="row" style={{ alignItems: "center" }}>
                                                    {domain.leadPdfFile ? <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="basicpill-companyuin-input" className="form-label">Файл pdf</label><br />
                                                            <a href={"/file-store/" + domain.leadPdfFile} target="_blank">Посмотреть текущий файл</a>
                                                        </div>
                                                    </div> : ""}
                                                    <div className="col-lg-6">
                                                        <input type="file" onChange={fileInputChange} accept="application/pdf" className="form-control" id="upload-pdf-input" />
                                                        <a href={"/"} className="btn btn-primary w-md waves-effect waves-light" onClick={(event) => { event.preventDefault(); document.querySelector("#upload-pdf-input").click() }}>Выбрать файл</a>
                                                    </div>
                                                </div> : ""}
                                                <div className="d-flex align-items-start" style={{ marginBottom: "10px" }}>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" onChange={dynamicStylesChange} checked={domain.dynamicStyles} type="checkbox" name="dynamicStyles" key="flexSwitchCheckDefault" id="flexSwitchCheckDefault" />
                                                        <label class="form-check-label" for="flexSwitchCheckDefault">Динамические стили</label>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-start" style={{ marginBottom: "10px" }}>
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" onChange={dynamicAdvantagesChange} checked={domain.advantages_alt} type="checkbox" name="dynamicStyles" key="flexSwitchCheckDefault" id="flexSwitchCheckDefault" />
                                                        <label class="form-check-label" for="flexSwitchCheckDefault">Альтернативные преимущества</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr className="my-4" />

                                <div className="py-2 mt-3">
                                    <h5 className="font-size-15">Шаги</h5>
                                </div>
                                <div className="p-4 border rounded">
                                    <div className="table-responsive">
                                        <table className="table table-nowrap align-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "70px" }}>Номер</th>
                                                    <th>Название шага</th>
                                                    <th className="text-end" style={{ width: "120px" }}>Тип</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {domain.steps.map((step, index) => <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>
                                                        <h5 className="font-size-15 mb-1">{step.title}</h5>
                                                        {step.answers.map((answer) => {
                                                            return <p className="font-size-13 text-muted mb-0">{answer.title}</p>
                                                        })}
                                                    </td>
                                                    <td className="text-end">{step.type}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    )
}


class DomainPageContainer extends Component {

    componentDidMount() {
    }

    render() {
        const { domain, loading, error, fetchDomain } = this.props;

        return <DomainDetail domain={domain} error={error} fetchDomain={fetchDomain} />;
    }
}

const mapStateToProps = ({ domainDetail: { domain, loading, error } }) => {
    return { domain, loading, error };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return bindActionCreators({
        fetchDomain: fetchDomain(apiService),
    }, dispatch);
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(DomainPageContainer);