import { useEffect, useState } from "react"

export const FlatType = ({ index, updateFilter, param }) => {
    const [selected, setSelected] = useState([])

    const FlatType = {
        "Студия": 0,
        "Однокомнатная": 1,
        "Двухкомнатная": 2,
        "Трехкомнатная": 3,
        "Четырехкомнатная": 4,
        "Пять и более комнат": 5,
    };

    const addSelected = (newClass) => {
        if (selected.includes(newClass)) {
            return
        }
        let newSelected = selected
        newSelected.push(newClass)
        setSelected([...newSelected])
    }

    const deleteClass = (name) => {
        let newSelected = selected
        for (let i = 0; i < newSelected.length; i++) {
            if (newSelected[i] === name) {
                newSelected.splice(i, 1)
            }
        }
        setSelected([...newSelected])
    }

    const getFlatName = (flatType) => {
        let name = ""
        Object.keys(FlatType).forEach((item)=>{
            if (FlatType[item] === flatType) {
                name = item
            }
        })
        return name
    }

    useEffect(()=>{
        if (param.filter.flatType) {
            setSelected(param.filter.flatType)
        }
    },[])

    useEffect(() => {
        updateFilter(selected, "flatType")
    }, [selected])

    return (
        <div key={index} className="row mb-3">
            <div className="col-lg-9 ml-2" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {
                    selected.map((flatType, ind) => {
                        return <div onClick={() => { deleteClass(flatType) }} key={"classes" + index + "_" + ind} className="btn btn-primary mb-2" style={{ marginRight: "20px", alignItems: "center" }}>
                            {getFlatName(flatType)}
                            <i className="feather-trash" style={{ marginLeft: "2px", color: "red", hover: "cursor" }} />
                        </div>
                    })
                }
            </div>
            <div className="col-lg-3 ml-2">
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <label htmlFor="basicpill-lastname-input" className="form-label">Добавить</label>
                    {<select className="form-select custom-select" id="template" name="template_id" value={""} >
                        <option></option>
                        {Object.keys(FlatType).map((key) => {
                            return <option onClick={() => addSelected(FlatType[key])}>{key}</option>
                        })}
                    </select>}
                </div>
            </div>
        </div>
    )
}