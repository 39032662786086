import axios from "axios"
import React, { useEffect, useState } from "react"
import { DomainJkItemSelect } from "./domain-jk-items-select"

export const DomainJkItems = ({ jkList, setJkList, portalJkList, cityId }) => {
    const [selectedJks, setSelectedJks] = useState([])
    const [selectOptions, setSelectOptions] = useState([])
    const addJk = () => {
        setSelectedJks([...selectedJks, {label: "", value:""}])
    }

    const getJkList = async () => {
        let resp = await axios.get("/jks/"+cityId)
        let options = []

        resp.data.jks.forEach(element => {
            options.push({
                label: element.name,
                value: element.id
            })        
        });

        setSelectOptions(options)
    }

    useEffect(()=>{
        if (cityId) {
            getJkList()
        }
    },[cityId])

    const setSelectedJk = (id, index) => {
        let jks = [...selectedJks]
        jks[index] = id
        setSelectedJks([...jks])
    }

    useEffect(()=>{
        if (selectedJks) {
            setJkList([...selectedJks])
        }
    },[selectedJks])

    useEffect(()=>{
        if ((selectedJks === null || selectedJks.length == 0) && jkList.length > 0) {
            setSelectedJks(jkList)
        }
    },[jkList])

    if (selectedJks === null) {
        return <div>loading</div>
    }
    
    return (
        <React.Fragment >
            <div className="text-center mb-4">
                <h5>ЖК</h5>
                <p className="card-title-desc">Выберите список жк, который будет участвовать в выборке</p>
            </div>

            {selectedJks.map((id, index)=>{
                console.log(id)
                return  <DomainJkItemSelect index={index} id={id} setSelectedJk={setSelectedJk} options={selectOptions}/>
            })}
           
            <div className="d-flex justify-content-center">
                <button className="btn btn-primary" onClick={addJk}><i className="feather-plus"></i> Добавить жк </button>
            </div>
        </React.Fragment>
    )
}