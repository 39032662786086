import { useEffect, useState } from "react"
import { JkClass } from "../../../enums"

export const SdachaType = ({ index, updateFilter, param }) => {
    const [sdachaList, setSdachaList] = useState([])
    const [selected, setSelected] = useState(param.filter.sdacha)

    const addSelected = (newClass) => {
        if (selected.includes(newClass)) {
            return
        }
        let newSelected = selected
        newSelected.push(newClass)
        setSelected([...newSelected])
    }

    const deleteClass = (name) => {
        let newSelected = selected
        for (let i = 0; i < newSelected.length; i++) {
            if (newSelected[i] === name) {
                newSelected.splice(i, 1)
            }
        }
        setSelected([...newSelected])
    }

    

    useEffect(() => {
        updateFilter(selected, "sdacha")
    }, [selected])


    useEffect(()=>{
        let year = new Date().getFullYear()
        let list = [year]
        for( let i=1; i < 20; i ++) {
            list.push(year+i)
        }
        setSdachaList(list)
    },[])

    return (
        <div key={index} className="row mb-3">
            <div className="col-lg-9 ml-2" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {
                    selected.map((classJK, ind) => {
                        return <div onClick={() => { deleteClass(classJK) }} key={"classes" + index + "_" + ind} className="btn btn-primary mb-2" style={{ marginRight: "20px", alignItems: "center" }}>
                            {classJK}
                            <i className="feather-trash" style={{ marginLeft: "2px", color: "red", hover: "cursor" }} />
                        </div>
                    })
                }
            </div>
            <div className="col-lg-3 ml-2">
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <label htmlFor="basicpill-lastname-input" className="form-label">Добавить</label>
                    {<select className="form-select custom-select" id="template" name="template_id" value={""} >
                        <option></option>
                        {sdachaList.map((value) => {
                            return <option onClick={() => addSelected(value)}>{value}</option>
                        })}
                    </select>}
                </div>
            </div>
        </div>
    )
}