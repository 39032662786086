import PageTitle from "../../../page-title"
import { compose } from "../../../../utils";
import { withApiService } from "../../../hoc";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom'
import { Component, useEffect, useState } from "react";
import { addCityToList, addDomainToList, fetchPermissions, updatePermissions, fetchUser, removeFromCityList, removeFromDomainList } from "../../../../actions/user-detail";
import { Spinner } from "../../../spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../../services/api-service";

const UserDetail = ({ user, permissions, loading, fetchUser, fetchPermissions, addCityToList, addDomainToList, updatePermissions, removeFromCityList, removeFromDomainList }) => {
    const { id } = useParams()
    const [sendSms, setSendSms] = useState(false)
    const [smsText, setSmsText] = useState("")
    const [domainsList, setDomainsList] = useState()
    const [domainsListReversed, setDomainsListReversed] = useState()
    const [citiesList, setCitiesList] = useState()
    const [selected, setSelected] = useState()

    const [form, setForm] = useState({})
    useEffect(() => {
        fetchUser(id)

        let apiService = new ApiService
        apiService.citiesList().then((response) => {
            setCitiesList(response.data)
        })
        apiService.domainsList("", "", 9999999, 0).then((response) => {
            let domainsList = {}
            let domainsListReversed = {}
            response.data.domains.sort((a, b) => (a.url > b.url) ? 1 : -1).forEach(domain => {
                domainsList[domain.id] = domain.url
                domainsListReversed[domain.url] = domain.id
            });
            setDomainsList(domainsList)
            setDomainsListReversed(domainsListReversed)
        })
    }, [id])

    useEffect(() => {
        if (!permissions || permissions.user_id != id) {
            fetchPermissions(id)
        }
    }, [user, id])
    const navigate = useNavigate()

    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(' / ')
    }

    if (loading || user == null) {
        return <Spinner />
    }

    const editClick = (event) => {
        event.preventDefault()
        navigate(event.target.getAttribute('href'), { replace: false })
    }

    const inputChange = (event) => {
        setSmsText(event.target.value)
    }

    const caseRole = (role) => {
        switch (role) {
            case "admin":
                return "администратор"
            case "custom":
                return "кастомный"
        }
    }

    const showModal = (modal) => {
        document.querySelector('#inlineForm').style.display = "block"
        document.querySelector('body').classList.add('modal-open')
        document.querySelectorAll('.modal-dialog').forEach((modal) => {
            modal.style.display = "none"
        })
        //document.querySelector('.modal-backdrop').classList.add('show')
        if (!document.querySelector('.modal-backdrop')) {
            var backdrop = document.createElement("div");
            backdrop.classList.add('modal-backdrop')
            backdrop.classList.add('fade')
            //document.querySelector('.app-content').append(backdrop)
            backdrop.classList.add('show')
            //<div className="modal-backdrop fade show"></div>
        }
        let element = document.createElement("div")
        element.setAttribute("id", "shadow")
        element.classList.add('modal-backdrop')
        element.classList.add('fade')
        element.classList.add('show')
        document.querySelector('body').appendChild(element)
        setTimeout(() => {
            document.querySelector('#inlineForm').classList.add('show')
            document.querySelector('.' + modal).style.display = "flex"
        }, 200)
        document.querySelector('.' + modal).style.display = "flex"
    }

    const closeModal = (e) => {
        e.preventDefault()
        document.querySelector('#inlineForm').style.display = "none"
        document.querySelector('#inlineForm').classList.remove('show')
        document.querySelector('body').classList.remove('modal-open')
        document.querySelectorAll('.modal-dialog').forEach((modal) => {
            modal.style.display = "none"
        })
        document.querySelector('.modal-backdrop').remove()


    }

    const addCity = (e) => {
        e.preventDefault()
        let form = e.target.closest('form')
        let select = form.querySelector('select')
        addCityToList(select.value)
        setSelected(select.value)
        closeModal(e)
    }

    const addDomain = (e) => {
        e.preventDefault()
        let form = e.target.closest('form')
        let select = form.querySelector('select')
        addDomainToList(select.value)
        setSelected(select.value)
        closeModal(e)
    }

    const removeCity = (e) => {
        removeFromCityList(e.target.getAttribute("id"))
        setSelected(e.target.getAttribute("id"))
    }

    const removeDomain = (e) => {
        removeFromDomainList(e.target.getAttribute("id"))
        setSelected(e.target.getAttribute("id"))
    }






    return (<div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle title={"Детальная информация по домену"} />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="invoice-title">
                                    <div className="d-flex align-items-start">
                                        <div className="flex-grow-1">
                                            <div className="mb-4">
                                                <span className="logo-txt">{user.Name}</span>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div className="mb-4">
                                                <a className="btn btn-primary w-md waves-effect waves-light" onClick={(e) => { e.preventDefault(); console.log(updatePermissions); updatePermissions(permissions) }}>Сохранить изменения</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-1"><b>ФИО пользователя:</b> {user.Name}</p>
                                    <p className="mb-1"><b>Логин:</b> {user.Login}</p>
                                    <p className="mb-1"><b>Роль:</b> {caseRole(user.Role)}</p>
                                    <div className="column">
                                        <b>Список доступных городов (нажми чтобы удалить):</b>
                                        <div className="" style={{ display: "flex", justifyContent: "start" }}>
                                            <div className="d-flex justify-content-center" style={{ margin: "10px" }} key={"cities"}>
                                                {permissions && permissions.cities_list.map((id) => {
                                                    let name = null
                                                    if (citiesList) {
                                                        name = citiesList.filter(city => city.ID == id)[0]?.Name
                                                    }
                                                    return <span className="btn btn-info" style={{ marginRight: "10px" }} onClick={removeCity} id={id} key={id}>{name ? name : id}</span>
                                                })}
                                                <button className="btn btn-secondary" onClick={() => { showModal('addCity') }}><i className="feather-plus"></i> Добавить</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <b>Список доступных доменов (нажми чтобы удалить):</b>

                                    </div>
                                    <div>
                                        <div className="row">
                                            <div class="col-lg-9 ml-2" style={{ display: "flex", flexFlow: "row wrap" }}>
                                               
                                                    {permissions && permissions.ids_list.map((id) => {
                                                        return <button className="btn btn-info mb-2" style={{ marginRight: "10px" }} onClick={removeDomain} id={id} key={id}>{domainsList && domainsList[id] ? domainsList[id] : id}</button>
                                                    })}
                                                    <button className="btn btn-secondary" onClick={() => { showModal('addDomain') }}><i className="feather-plus"></i> Добавить</button>
                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade show" id="inlineForm" aria-labelledby="myModalLabel33" style={{ display: "" }} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered addCity">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel33">Добавить город</h4>
                        <br />
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    <div>
                    </div>
                    <form action="#">
                        <div className="modal-body">
                            <label>Город:</label>
                            <div className="form-group">
                                <select onChange={inputChange} className="form-select custom-select" id="template" name="city_id">
                                    <option></option>
                                    {citiesList && citiesList.map((city) => {
                                        return <option value={city.ID}>{city.Name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary waves-effect waves-float waves-light" data-dismiss="modal" onClick={closeModal}>Отмена</button>
                                <button type="button" className="btn btn-primary waves-effect waves-float waves-light" data-dismiss="modal" onClick={addCity}>Добавить</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="modal-dialog modal-dialog-centered addDomain">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="myModalLabel33">Добавить домен</h4>
                        <br />
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    <div>
                    </div>
                    <form action="#">
                        <div className="modal-body">
                            <label>Домен:</label>
                            <div className="form-group">
                                <select onChange={inputChange} className="form-select custom-select" id="template" name="city_id">
                                    <option></option>
                                    {domainsList && Object.keys(domainsList).map((domain) => {
                                        return <option value={domain}>{domainsList[domain]}</option>
                                    })}
                                </select>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary waves-effect waves-float waves-light" data-dismiss="modal" onClick={closeModal}>Отмена</button>
                                <button type="button" className="btn btn-primary waves-effect waves-float waves-light" data-dismiss="modal" onClick={addDomain}>Добавить</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    )
}


class UserPageContainer extends Component {

    componentDidMount() {
    }

    render() {
        const { user, permissions, loading, error, fetchUser, fetchPermissions, addCityToList, addDomainToList, updatePermissions, removeFromCityList, removeFromDomainList } = this.props;

        return <UserDetail user={user} permissions={permissions} error={error} fetchUser={fetchUser} fetchPermissions={fetchPermissions} addCityToList={addCityToList} addDomainToList={addDomainToList} updatePermissions={updatePermissions} removeFromCityList={removeFromCityList} removeFromDomainList={removeFromDomainList} />;
    }
}

const mapStateToProps = ({ userDetail: { user, permissions, loading, error } }) => {
    return { user, permissions, loading, error };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return bindActionCreators({
        fetchUser: fetchUser(apiService),
        fetchPermissions: fetchPermissions(apiService),
        addCityToList: addCityToList,
        addDomainToList: addDomainToList,
        removeFromCityList: removeFromCityList,
        removeFromDomainList: removeFromDomainList,
        updatePermissions: updatePermissions(apiService),
    }, dispatch);
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(UserPageContainer);