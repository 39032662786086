const updateUsersList = (state, action) => {

    if (state === undefined) {
        return {
            users: null,
            loading: false,
            error: false,
        };
    }

    switch (action.type) {
        case 'USERS_REQUESTED':
            return {
                users: null,
                loading: true,
                error: false,
            };
        case 'USERS_FETCH_SUCCESS':
            return {
                users: action.payload,
                loading: false,
                error: false,
            };
        case 'USERS_ERROR':
            return {
                users: state.usersList.users,
                loading: false,
                error: true,
            };
        case 'USER_DELETE_ONE':
            return {
                users: state.usersList.users,
                loading: false,
                error: true,
            };
        case 'USER_UPDATE':
            return {
                users: state.usersList.users,
                loading: false,
                error: true,
            };
        default:
            return state.usersList;
    }
};

export default updateUsersList;