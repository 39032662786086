const userDetailInfoRequest = () => {
    return {
        type: 'USER_REQUESTED',
    }
}

const userDetailInfoSuccess = (data) => {
    return {
        type: 'USER_FETCH_SUCCESS',
        payload: data
    }
}

const userPermissionsRequest = () => {
    return {
        type: 'PERMISSIONS_REQUESTED',
    }
}

const userDetailInfoError = (id) => {
    return {
        type: 'USER_FETCH_ERROR',
        payload: id
    }
}

const userPermissionsSuccess = (data) => {
    return {
        type: 'PERMISSIONS_FETCH_SUCCESS',
        payload: data
    }
}

const userPermissionsError = (data) => {
    return {
        type: 'PERMISSIONS_FETCH_ERROR',
        payload: data
    }
}

const updateUser = (user) => {
    return {
        type: 'USER_UPDATE_SUCCESS',
        payload: user
    }
}

const addCityToList = (id) => {
    return {
        type: 'ADD_CITY_TO_PERMISSIONS',
        payload: id,
    }
}

const removeFromCityList = (id) => {
    return {
        type: 'REMOVE_CITY_PERMISSIONS',
        payload: id,
    }
}

const addDomainToList = (id) => {
    return {
        type: 'ADD_DOMAIN_TO_PERMISSIONS',
        payload: id,
    }
}

const removeFromDomainList = (id) => {
    return {
        type: 'REMOVE_DOMAIN_PERMISSIONS',
        payload: id,
    }
}

const fetchUser = (apiService) => (id) => (dispatch) => {
    dispatch(userDetailInfoRequest)
    apiService.user(id)
        .then((data) => {dispatch(userDetailInfoSuccess(data.data))})
        .catch((err) => dispatch(userDetailInfoError(err)))
}

const fetchPermissions = (apiService) => (id) => (dispatch) => {
    dispatch(userPermissionsRequest)
    apiService.usersPermissions(id)
        .then((data) => {dispatch(userPermissionsSuccess(data.data))})
        .catch((err) => dispatch(userPermissionsError(err)))
}

const updatePermissions = (apiService) => (permissions) => (dispatch) => {
    apiService.usersPermissionsSave(permissions)
        .then((data)=> {console.log(data)})
        .catch((err)=> {console.log(err)})
}

/*const addUser = (apiService) => (user) => (dispatch) => {
    apiService.addUser(user)
        .then((data) => dispatch(addUserAction(data)))
        .catch((err) => dispatch(userDetailInfoError(err)))
}*/

const editUser = (apiService) => (data) => (dispatch) => {
    apiService.editUser(data)
        .then((data) => dispatch(updateUser(data)))
        .catch((err) => dispatch(userDetailInfoError(err)))
}

export {
    fetchUser,
    fetchPermissions,
    addCityToList,
    addDomainToList,
    updatePermissions,
    removeFromCityList,
    removeFromDomainList
}