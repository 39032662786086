import axios from "axios"
import { useState } from "react"
import PageTitle from "../../page-title"

export const PhonesPage = () => {
    const [numbers, setNumbers] = useState("")
    const [validNumbers, setValidNumbers] = useState([])
    const [invalidNumbers, setInValidNumbers] = useState([])
    const [status, setStatus] = useState()

    const checkPhoneNumbers = async () => {
        setValidNumbers([])
        setInValidNumbers([])
        let newValidNumbers = [...validNumbers]
        let newInValidNumbers = [...invalidNumbers]
        setStatus("Проверка...")
        let numbersList  = numbers.split(',')
        for (let i=0; i< numbersList.length; i++) {
            let number = numbersList[i]
            if (number.length>10) {
                let resp = await axios.get('/lead/validator/'+number)
                if (resp.data && resp.data.valid) {
                    newValidNumbers.push(number)
                    setValidNumbers([...newValidNumbers])
                } else {
                    newInValidNumbers.push(number)
                    setInValidNumbers([...newInValidNumbers])
                }
            }
        }
        
        setStatus("Проверка завершена!")
    }

    const inputChange = (e) => {
        setNumbers(e.target.value)
    }

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <PageTitle title={"Валидация списка номеров"} />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <label htmlFor="basicpill-servicetax-input" className="form-label">Список номеров (через запятую)</label>
                                                <textarea type="text" className="form-control" name="numbers" value={numbers} onChange={inputChange} style={{minHeight: "200px"}}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-primary" onClick={checkPhoneNumbers}>Проверить номера</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12" style={{ marginBottom: "10px" }}>
                            <h4 class="form-check-label" for="flexSwitchCheckDefault" style={{ marginBottom: "10px" }}>{status}</h4>
                            <h5 class="form-check-label" for="flexSwitchCheckDefault" style={{ marginBottom: "10px" }}>Результат</h5>
                            <div class="row">
                                <div className="col-lg-6">
                                    <h7 style={{ fontWeight: "bold" }}>Валидные номера</h7>
                                    <div className="result" style={{marginTop: "10px", display:"flex", flexDirection:"column"}}>
                                        {validNumbers.map((number)=>{
                                            return <span>{number}</span>
                                        })}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h7 style={{ fontWeight: "bold" }}>Не валидные номера</h7>
                                    <div className="result" style={{marginTop: "10px", display:"flex", flexDirection:"column"}}>
                                        {invalidNumbers.map((number)=>{
                                            return <span>{number}</span>
                                        })}
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}