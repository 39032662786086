import React, { useState } from "react"
import { ClassType } from "./paramTypes/classType"
import { FlatType } from "./paramTypes/flatType"
import { PriceType } from "./paramTypes/priceType"
import { RaionsType } from "./paramTypes/raionsType"
import { SdachaType } from "./paramTypes/sdachaType"

export const Param = ({ index, length, update, param, cityId }) => {
    const [selectedType, setSelectedType] = useState("class")

    const paramTypes = {
        "Класс ЖК": "class",
        "Год сдачи": "sdacha",
        "Тип квартиры": "flatType",
        "Диапозон цен": "prices",
        "Районы": "raions",
    }

    const addFilter = () => {
        let newParam = param
        let added = false
        Object.keys(paramTypes).forEach(element => {
            if (!Object.keys(param.filter).includes(paramTypes[element]) && !added) {
                param.filter[paramTypes[element]] = []
                added = true
            }
        });
        update(index, newParam)
    }

    const updateInput = (e) => {
        let newParam = param
        newParam[e.target.name] = e.target.value
        if (e.target.name === "fontSize") {
            newParam[e.target.name] = parseInt(e.target.value)
        }
       
        update(index, newParam)
    }

    const updateFilter = (filter, key) => {
        let newParam = param
        newParam.filter[key] = filter
        update(index, newParam)
    }

    const exchangeFilter = (oldFilterIndex, newFilter) => {
       
        let newParam = param
        delete newParam.filter[Object.keys(newParam.filter)[oldFilterIndex]]
        newParam.filter[newFilter] = []
        update(index, newParam)
    }

    const deleteFilter = (value) => {
        let newParam = param
        delete newParam.filter[value]
        update(index, newParam)
    }

    const downloadPdf = () => {
        let data = {
            cityId: parseInt(cityId),
            ...param
        }


        fetch('/jks/pdf', { method: 'POST', body: JSON.stringify(data) })
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                let filename = "Каталог.pdf";
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename; 
                document.body.appendChild(link); 
                link.click(); 
                document.body.removeChild(link);
            });
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>Подборка {index + 1} / {length}</div>
                        <i onClick={() => { }} className="feather-trash" style={{ color: "red", marginLeft: "10px" }} />
                    </div>
                    <button className="btn btn-primary" onClick={downloadPdf}><i className="feather-book"></i> Сгенерировать подборку</button>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="basicpill-servicetax-input" className="form-label">Заголовок титульника подборки</label>
                        <input style={{ }} type="text" autoComplete="off" onChange={updateInput} className="form-control" name="title" id="basicpill-servicetax-input" value={param.title} /> 
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="basicpill-servicetax-input" className="form-label">Размер шрифта титульника</label>
                        <input style={{ }} type="number" autoComplete="off" onChange={updateInput} className="form-control" name="fontSize" id="basicpill-servicetax-input" value={param.fontSize} /> 
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="basicpill-servicetax-input" className="form-label">Параметр url</label>
                        <input style={{ }} type="text" autoComplete="off" onChange={updateInput} className="form-control" name="urlParam" id="basicpill-servicetax-input" value={param.urlParam} /> 
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mb-3">
                        <label htmlFor="basicpill-servicetax-input" className="form-label">Значение параметра url</label>
                        <input style={{ }} type="text" autoComplete="off" onChange={updateInput} className="form-control" name="urlValue" id="basicpill-servicetax-input" value={param.urlValue} /> 
                    </div>
                </div>
            </div>

            {param.filter && Object.keys(param.filter).map((key, filterIndex) => {
                return <React.Fragment>
                    <div className="row" style={{alignItems:"center"}}>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <label htmlFor="basicpill-servicetax-input" className="form-label">Тип фильтра</label>
                                <select className="form-select custom-select" id="template" name="template_id"  value={key} >
                                    {Object.keys(paramTypes).map((key) => {
                                        return <option value={paramTypes[key]}  onClick={() => { exchangeFilter(filterIndex, paramTypes[key]) }} >{key}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <i className="feather-trash" style={{ marginLeft: "2px", color: "red", hover:"cursor" }} onClick={()=>deleteFilter(key)} />
                        </div>
                    </div>
                    <label htmlFor="basicpill-servicetax-input" className="form-label">Значения:</label>
                    {(() => {
                        switch (key) {
                            case "class":
                                return <ClassType index={filterIndex} updateFilter={updateFilter} param={param} />
                            case "sdacha":
                                return <SdachaType index={filterIndex} updateFilter={updateFilter} param={param} />
                            case "flatType":
                                return <FlatType index={filterIndex} updateFilter={updateFilter} param={param} />
                            case "raions":
                                return <RaionsType index={filterIndex} updateFilter={updateFilter} cityId={cityId} param={param} />
                            case "prices":
                                return <PriceType index={filterIndex} updateFilter={updateFilter} cityId={cityId} param={param} />
                        }
                    })()}

                </React.Fragment>
            })}
            { param.filter && Object.keys(param.filter).length < Object.keys(paramTypes).length?<div className="d-flex justify-content-center">
                <button className="btn btn-secondary" onClick={addFilter}><i className="feather-plus"></i> фильтр </button>
            </div>:""}
            <hr class="my-4" />
        </React.Fragment>

    )
}