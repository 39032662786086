const updateSchedulesList = (state, action) => {

    if (state === undefined) {
      return {
        schedules: null,
        loading: false,
        error: false,
      };
    }
  
    switch (action.type) {
        case 'SCHEDULES_REQUESTED':
            return {
                schedules: null,
                loading: true,
                error: false,
            };
        case 'SCHEDULES_FETCH_SUCCESS':
            return {
                schedules: action.payload,
                loading: false,
                error: false,
            };
        case 'SCHEDULES_ERROR':
            return {
                schedules: state.schedulesList.schedules,
                loading: false,
                error: true,
            };
        case 'SCHEDULE_DELETE_ONE':
            return {
                schedules: state.schedulesList.schedules,
                loading: false,
                error: true,
            };
        case 'SCHEDULE_UPDATE':
            return {
                schedules: state.schedulesList.schedules,
                loading: false,
                error: true,
            };
      default:
        return state.schedulesList;
    }
  };
  
export default updateSchedulesList;