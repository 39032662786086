const spamRequested = () => {
    return {
        type: 'SPAM_REQUESTED',
    }
}

const spamRequestSuccess = (spam) => {
    return {
        type: 'SPAM_FETCH_SUCCESS',
        payload: spam
    }
}

const spamRequestError = () => {
    return {
        type: 'SPAM_FETCH_ERROR',
    }
}


const fetchSpamList = (apiService) => (from, to) => (dispatch) => {
    dispatch(spamRequested)
    apiService.spamList(from, to)
        .then((response) => dispatch(spamRequestSuccess(response.data)))
        .catch((err) => dispatch(spamRequestError(err)))
}

export {
    fetchSpamList,
}