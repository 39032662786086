import React, { useState } from "react"
import { Param } from "./param"

export const PdfParams = ({cityId, pdfParams, setPdfParams}) => {

    const update = (index, param) => {
        console.log("update param")
        let newParams = pdfParams
        newParams[index] = param
        setPdfParams([...newParams])
    }

    const addParam = () => {
        let newParams = pdfParams
        newParams.push({
            title: "",
            fontSize: 0,
            urlParam: "",
            urlValue: "",
            filter: {
                class: [],
                
            },
        })
        setPdfParams([...newParams])
    }
    return (
        <React.Fragment>
            <div className="text-center mb-4">
                <h5>Параметры выборки ЖК</h5>
                <p className="card-title-desc">Заполните параметры для pdf каталога</p>
            </div>
            {pdfParams && pdfParams.map((param, index)=>{
                return <Param index={index} length={pdfParams.length} update={update} param={param} cityId={cityId}/>
            })}
            <div className="d-flex justify-content-center">
                <button className="btn btn-primary" onClick={()=>{addParam()}}><i className="feather-plus"></i> Добавить подборку </button>
            </div>
        </React.Fragment>
    )
}

export default PdfParams