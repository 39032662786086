export const TextAnswer = ({ answer, index, updateAnswer, deleteAnswer }) => {
    const updateTitle = (title) => {
        let newAnswer = answer
        newAnswer.title = title 
        updateAnswer(index, newAnswer)
    }
    const updatePipelineId = (pipelineId) => {
        let newAnswer = answer
        newAnswer.pipelineId = parseInt(pipelineId) 
        updateAnswer(index, newAnswer)
    }
    const updateStatusId = (statusId) => {
        let newAnswer = answer
        newAnswer.statusId = parseInt(statusId)
        updateAnswer(index, newAnswer)
    }
    const updateSId = (sId) => {
        let newAnswer = answer
        newAnswer.sId = parseInt(sId)
        updateAnswer(index, newAnswer)
    }
    return <div key={index} className="row">
        <div className="col-lg-6 ml-2">
            <label htmlFor="" style={{ marginLeft: "30px" }}>Текст ответа</label>
            <div className="mb-3" style={{ display: "flex", marginLeft: "30px", alignItems: "center" }}>
                {index + 1}.  <input style={{ marginLeft: "10px", marginRight: "10px" }} type="text" autoComplete="off" onChange={(e) => {updateTitle(e.target.value)}} className="form-control" name="mail" id="basicpill-servicetax-input" value={answer.title} /> <i onClick={() => { deleteAnswer(index) }} className="feather-trash" style={{ color: "red" }} />
            </div>
           
        </div>
        <div className="col-lg-6 ml-2">
            <div className="row">
                <div className="col-lg-4" >
                    <label htmlFor="" style={{ marginLeft: "10px" }}>Номер воронки</label>
                    <input style={{ marginLeft: "10px", marginRight: "10px" }} placeholder="Введите номер воронки" type="number" autoComplete="off" onChange={(e) => {updatePipelineId(e.target.value)}} className="form-control" name="mail" id="basicpill-servicetax-input" value={answer.pipelineId} />
                </div>
                <div className="col-lg-4" >
                    <label htmlFor="" style={{ marginLeft: "10px" }}>Номер статуса</label>
                    <input style={{ marginLeft: "10px", marginRight: "10px" }} placeholder="Введите номер воронки" type="number" autoComplete="off" onChange={(e) => {updateStatusId(e.target.value)}} className="form-control" name="mail" id="basicpill-servicetax-input" value={answer.statusId} />
                </div>
                <div className="col-lg-4" >
                    <label htmlFor="" style={{ marginLeft: "10px" }}>Sid портала</label>
                    <input style={{ marginLeft: "10px", marginRight: "10px" }} placeholder="Введите номер воронки" type="number" autoComplete="off" onChange={(e) => {updateSId(e.target.value)}} className="form-control" name="mail" id="basicpill-servicetax-input" value={answer.sId} />
                </div>
            </div>
        </div>
        
    </div>
}