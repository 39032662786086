import React, { useEffect } from "react"
import { useState } from "react"
import { QuestionType } from "../../../enums"
import { ClassAnswer } from "./classAnswer"
import { CompletionAnswer } from "./completionAnswer"
import { DistrictAnswer } from "./districtAnswer"
import { PriceAnswer, PriceSliderAnswer } from "./priceSliderAnswer"
import { PriceVariantsAnswer } from "./priceVariantsAnswer"
import { RaionAnswer } from "./raionAnswer"
import { RoomsAnswer } from "./roomsAnswer"
import { Answer, TextAnswer } from "./textAnswer"

export const QuizStep = ({ index, step, length, raions, deleteStep, updateQuizStep}) => {

    const [localRaions, setLocalRaions] = useState([])
    const [type, setType] = useState("Районы")
    const questionsTypes = {
        [QuestionType.SimpleQuestion]: "Текстовый",
        [QuestionType.RaionsQuestion]: "Районы",
        [QuestionType.DistrictQuestion]: "Округи",
        [QuestionType.RoomsQuestion]: "Комнатность",
        [QuestionType.ClassQuestion]: "Класс",
        [QuestionType.CompletionQuestion]: "Сдача",
        [QuestionType.PriceQuestionSlider]: "Стоимость (слайдер)",
        [QuestionType.PriceQuestionVariants]: "Стоимость (варианты)",
    }


    const updateQuestionType = (type) => {
        let newStep = step
        newStep.questionType = parseInt(type)
        updateQuizStep(index, newStep)
    }

    const updateQuestionTitle = (title) => {
        let newStep = step
        newStep.title = title
        updateQuizStep(index, newStep)
    }

    const updateAnswer = (ind, answer) => {
        let newStep = step
        newStep.answers[ind] = answer
        if (step.questionType == QuestionType.RaionsQuestion) {
            updateRaions()
        }
        updateQuizStep(index, newStep)
    }

    const addAnswer = () => {
        let newStep = step
        newStep.answers.push({
            title:"",
            param:"",
            pipelineId: 0,
            statusId: 0,
            sId: 0,
        })
        updateQuizStep(index, newStep)
    }

    const deleteAnswer = (ind) => {
        let newStep = step
        newStep.answers.splice(ind, 1)
        console.log(newStep)
        updateQuizStep(index, newStep)
    }

    const updateRaions = () => {
        if (step.questionType == QuestionType.RaionsQuestion) {
            let newRaions = []
            raions.forEach((raion)=>{
                let flag  = true
                step.answers.forEach((answer) => {
                    if (answer.param == raion.code) {
                        flag = false
                    }
                })
                if (flag) {
                    newRaions.push(raion)
                }
            })
            setLocalRaions(newRaions)
           
        }
    }

    useEffect(()=>{
        if (step.answers.length === 0) {
            let newStep = step
            newStep.answers = [{
                title:"",
                param:"",
            }]
            updateQuizStep(index, newStep)
        }
    },[step])

    useEffect(()=>{
        if (step.questionType == QuestionType.RaionsQuestion) {
            let newRaions = []
            raions.forEach((raion)=>{
                let flag  = true
                step.answers.forEach((answer) => {
                    if (answer.param == raion.id) {
                        flag = false
                    }
                })
                if (flag) {
                    newRaions.push(raion)
                }
            })
            setLocalRaions(newRaions)
        }
    },[step.questionType, raions])

    return <React.Fragment key={index}><div>
        <div className="row">
            <div className="mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Шаг {index + 1} / {length}</div>
                <i onClick={() => { deleteStep(index) }} className="feather-trash" style={{ color: "red" }} />
            </div>

        </div>
        <div className="row">
            <div className="col-lg-6">
                <div className="mb-3">
                    <label htmlFor="basicpill-servicetax-input" className="form-label">Текст вопроса</label>
                    <input type="text" indexstep={index} onChange={(e)=>{updateQuestionTitle(e.target.value)}} className="form-control" name="mail" id="basicpill-servicetax-input" value={step.title} />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="mb-3">
                    <label htmlFor="basicpill-servicetax-input" className="form-label">Тип шага</label>
                    <select className="form-select custom-select" id="template" name="template_id" value={step.questionType} >
                        {Object.keys(questionsTypes).map((key)=>{
                            return <option value={key} onClick={()=>{updateQuestionType(key)}}>{questionsTypes[key]}</option>
                        })}
                    </select>
                </div>
            </div>
        </div>
        <div className="mb-3" style={{ marginLeft: "15px",  }}>
            <span style={{marginRight:"15px"}}>Ответы на вопрос</span>{step.questionType!==QuestionType.PriceQuestionSlider?<button className="btn btn-primary" onClick={()=>{addAnswer()}}><i className="feather-plus"></i> Добавить ответ </button>:""}
        </div>
        {step.answers.map((answer, i)=>{
            switch(step.questionType) {
                case QuestionType.SimpleQuestion:
                    return <TextAnswer index={i} answer={answer} updateAnswer={updateAnswer} deleteAnswer={deleteAnswer} />
                case QuestionType.CompletionQuestion:
                    return <CompletionAnswer index={i} answer={answer} updateAnswer={updateAnswer} deleteAnswer={deleteAnswer} />
                case QuestionType.RaionsQuestion:
                    return <RaionAnswer index={i} answer={answer} step={step} raions={localRaions} updateAnswer={updateAnswer} deleteAnswer={deleteAnswer} />
                case QuestionType.RoomsQuestion:
                    return <RoomsAnswer index={i} answer={answer} updateAnswer={updateAnswer} deleteAnswer={deleteAnswer} />
                case QuestionType.ClassQuestion:
                    return <ClassAnswer index={i} answer={answer} updateAnswer={updateAnswer} deleteAnswer={deleteAnswer} />
                case QuestionType.PriceQuestionSlider:
                    return <PriceSliderAnswer index={i} answer={answer} updateAnswer={updateAnswer} deleteAnswer={deleteAnswer} />
                case QuestionType.PriceQuestionVariants:
                    return <PriceVariantsAnswer index={i} answer={answer} updateAnswer={updateAnswer} deleteAnswer={deleteAnswer} />
                case QuestionType.DistrictQuestion:
                    return raions.length==0?"loading":<DistrictAnswer index={i} step={step} answer={answer} updateAnswer={updateAnswer} raions={raions} deleteAnswer={deleteAnswer} />
            }
        })}

    </div>
        <hr className="my-4"></hr>
    </React.Fragment>
}