const usersRequested = () => {
    return {
        type: 'USERS_REQUESTED',
    }
}

const usersLoaded = (domains) => {
    return {
        type: 'USERS_FETCH_SUCCESS',
        payload: domains
    }
}

const usersError = (data) => {
    return {
        type: 'USERS_ERROR',
        payload: data
    }
}

const deleteUserAction = (id) => {
    return {
        type: 'USER_DELETE_ONE',
        payload: id
    }
}

const updateUserAction = (data) => {
    return {
        type: 'USER_UPDATE',
        payload: data,
    }
}

const fetchUsers = (apiService) => () => (dispatch) => {
    dispatch(usersRequested)
    apiService.usersList()
        .then((response) => dispatch(usersLoaded(response.data)))
        .catch((err) => dispatch(usersError(err)))
}

const deleteUser = (apiService) => (id) => (dispatch) => {
    apiService.deleteUser(id)
        .then((response) => dispatch(deleteUserAction(id)))
        .catch((err) => dispatch(usersError(err)))
}

const updateUser = (apiService) => (data) => (dispatch) => {
    apiService.updateUser(data)
        .then((response) => dispatch(updateUserAction(response.data)))
        .catch((err) => dispatch(usersError(err)))
}

export {
    fetchUsers,
    deleteUser,
    updateUser
}