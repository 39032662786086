import axios from "axios"
import { useEffect, useState } from "react"

export const RaionsType = ({ index, updateFilter, cityId, param }) => {
    const [selected, setSelected] = useState([])
    const [raions, setRaions] = useState([])

    const addSelected = (newClass) => {
        if (selected.includes(newClass)) {
            return
        }
        let newSelected = selected
        newSelected.push(newClass)
        setSelected([...newSelected])
    }

    const deleteClass = (name) => {
        let newSelected = selected
        for (let i = 0; i < newSelected.length; i++) {
            if (newSelected[i] === name) {
                newSelected.splice(i, 1)
            }
        }
        setSelected([...newSelected])
    }

    const getCityRaions = () => {
        if (cityId !== undefined){
            axios.get("/api/cities/raions/"+cityId).then((resp) => {
                setRaions(resp.data)
            })
        }
    }

    const getRaionName = (code) => {
        let name = ""
        raions.map((raion)=>{
            if (raion.code === code){
                name = raion.name
            }
        })
        return name
    }

    useEffect(()=>{
        getCityRaions()
    },[cityId])

    useEffect(()=>{
        if (param.filter.raions) {
            setSelected(param.filter.raions)
        }
    },[])

    useEffect(() => {
        updateFilter(selected, "raions")
    }, [selected])

   

    return (
        <div key={index} className="row mb-3">
            <div className="col-lg-9 ml-2" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {
                    selected.map((code, ind) => {
                        return <div onClick={() => { deleteClass(code) }} key={"classes" + index + "_" + ind} className="btn btn-primary mb-2" style={{ marginRight: "20px", alignItems: "center" }}>
                            {getRaionName(code)}
                            <i className="feather-trash" style={{ marginLeft: "2px", color: "red", hover: "cursor" }} />
                        </div>
                    })
                }
            </div>
            <div className="col-lg-3 ml-2">
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <label htmlFor="basicpill-lastname-input" className="form-label">Добавить</label>
                    {<select className="form-select custom-select" id="template" name="template_id" value={""} >
                        <option></option>
                        {raions.map((raion) => {
                            return <option onClick={() => addSelected(raion.code)}>{raion.name}</option>
                        })}
                    </select>}
                </div>
            </div>
        </div>
    )
}