import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "../../../../../services/api-service";
import { showModal } from "../../../../modals";

const TableItem = ({ testing: { id, first_domain, second_domain, first_unique_visits, second_unique_visits, active  }, fetchTesting }) => {
    const [show, setShow] = useState(false)
    const navigate = useNavigate()
    const convertDate = (inputFormat) => {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(' / ')
    }
    const showDropDown = (event) => {
        setShow(!show)
    }

    const hrefClick = (event) => {
        event.preventDefault()
        navigate(event.target.getAttribute('href'), { replace: false })
    }
    const deleteClick = (event) => {
        event.preventDefault()
        let apiService = new ApiService
        apiService.deleteTesting(id).then((response) => {
            fetchTesting()
        })
    }
    const caseRole = (role) => {
        switch(role) {
            case "admin":
                return "администратор"
            case "custom":
                return "кастомный"
        }
    }


    return (<tr key={id} className="odd">
        <td className="sorting_1">
            <div className="form-check font-size-16">
                <input type="checkbox" className="form-check-input" />
                <label className="form-check-label"></label>
            </div>
        </td>
        <td><a onClick={(e) => {e.preventDefault()}} href={id} className="text-dark fw-medium">{id}</a></td>
        <td>{first_domain}</td>
        <td>{second_domain}</td>
        <td>{first_unique_visits}/{second_unique_visits}</td>
        {<td>
            <div className="dropdown">
                <button onClick={showDropDown} className="btn btn-link" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="feather-more-horizontal"></i>
                </button>
                <ul className={show ? "dropdown-menu dropdown-menu-end show" : "dropdown-menu dropdown-menu-end"}>
                    <li><a className="dropdown-item" onClick={deleteClick} href="#">Удалить</a></li>
                </ul>
            </div>
        </td>}
    </tr>

    )
}

export default TableItem