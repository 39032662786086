import { useEffect, useState } from "react"

export const DistrictAnswer = ({ answer, step, index, raions, updateAnswer, deleteAnswer }) => {
    const [raionsList, setRaionsList] = useState(false)

    const updateTitle = (title) => {
        let newAnswer = answer
        newAnswer.title = title 
        updateAnswer(index, newAnswer)
    }

    const updateRaion = (index, name) => {
        setRaionsList((prevState)=>  {
            let newList = Array.from(prevState)
            let value = ""
            raions.forEach(raion => {
                if (raion.name == name) {
                    value = raion.code
                }
            });
            newList[index] = {
                name: name,
                value: value,
            }
            return newList
        })
    }

    const addNewRaion = () => {
        setRaionsList((prevState)=>  {
            let newList = Array.from(prevState)
            newList.push({
                name: "",
                value: "",
            })
            return newList
        })
    }

    const raionDelete = (index) => {
        setRaionsList((prevState)=>  {
            let newList = Array.from(prevState)
            newList.splice(index, 1)
            return newList
        })
    }

    useEffect(()=>{
        
        if (!raionsList && raions) {
            let newRaionsList = []
            answer.param.forEach((value) => {
                console.log(value)
                console.log(raions)
                raions.forEach(raion => {
                    console.log(raion.code == value)
                    if (raion.code == value) {
                        value = raion.code
                        newRaionsList.push({
                            name: raion.name,
                            value: raion.code
                        })
                        console.log({
                            name: raion.name,
                            value: raion.code
                        })
                    }
                });
            })
            console.log(newRaionsList)
            setRaionsList(newRaionsList)
            return
        }
        let newAnswer = answer
        let params = []
        raionsList.forEach(raion => {
            params.push(raion.value)
        });
        newAnswer.param = params
        updateAnswer(index, newAnswer)
    },[raions, raionsList])


    return <div key={index} className="column">
        <div className="row">
            <div className="col-lg-6 ml-2">
                <div className="mb-3" >
                    <label htmlFor="basicpill-lastname-input" className="form-label">Текст ответа</label>
                      <input style={{ marginLeft: "10px", marginRight: "10px" }} type="text" autoComplete="off" onChange={(e) => { updateTitle(e.target.value) }} className="form-control" name="mail" id="basicpill-servicetax-input" value={answer.title} /> 
                </div>
            </div>
            <div className="col-lg-6 ml-2">
                <div className="column">
                    {raionsList && raionsList.map((raion, index)=>{
                        return <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                    <div className="mb-3" style={{width:"100%", marginRight: "10px"}}>
                                        <label htmlFor="basicpill-lastname-input" className="form-label">Выберите район</label>
                                        <select className="form-select custom-select" id="template" onChange={(e) => updateRaion(index, e.target.value)} name="template_id" value={raionsList[index].name}>
                                            <option></option>
                                            {raions.map((raion)=>{
                                                return <option>{raion.name}</option>
                                            })}
                                            <option>{answer.title}</option>
                                        </select>
                                    </div>
                                    <i onClick={() => { raionDelete(index) }} className="feather-trash" style={{ color: "red" }} />
                                </div>
                    })}
                    <div className=" mb-3" style={{ width: "100%",  }}>
                        <button className="btn btn-primary col-lg-12" onClick={()=>{addNewRaion()}}><i className="feather-plus"></i></button>
                    </div>
                </div>
                
            </div>
            {step.answers.length-1!==index?<hr className="my-4"></hr>:""}
            
           
        </div>
    </div>
}