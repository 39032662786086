import { useEffect, useState } from "react"
import { JkClass } from "../../../enums"

export const ClassType = ({index, updateFilter, param}) => {
    const [selectedClasses, setSelected] = useState([])

    const addSelected = (newClass) => {
        if (selectedClasses.includes(newClass)) {
            return
        }
        let newSelected = selectedClasses
        newSelected.push(newClass)
        setSelected([...newSelected])
    }

    const deleteClass = (name) => {
        let newSelected = selectedClasses
        for (let i=0; i<newSelected.length; i ++) {
            if (newSelected[i] === name) {
                newSelected.splice(i, 1)
            }
        }
        setSelected([...newSelected])
    }

    

    useEffect(()=>{
        if (param.filter.class) {
            setSelected(param.filter.class)
        }
    },[])

    useEffect(()=>{
        updateFilter(selectedClasses, "class")
    },[selectedClasses])

    return (
        <div key={index} className="row mb-3">
            <div className="col-lg-9 ml-2" style={{display:"flex", flexDirection:"row", flexWrap: "wrap"}}>
                {
                selectedClasses.map((classJK, ind)=>{
                    return <div onClick={() => { deleteClass(classJK) }} key={"classes" + index+"_"+ind} className="btn btn-primary mb-2" style={{marginRight: "20px", alignItems:"center"}}>
                        {classJK}
                        <i  className="feather-trash" style={{ marginLeft: "2px", color: "red", hover:"cursor" }} />
                    </div>
                })
                }
            </div>
            <div className="col-lg-3 ml-2">
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                        <label htmlFor="basicpill-lastname-input" className="form-label">Добавить</label>
                        {<select className="form-select custom-select" id="template" name="template_id" value={""} >
                            <option></option>
                            {Object.keys(JkClass).map((key)=>{
                                return <option onClick={() => addSelected(JkClass[key])}>{JkClass[key]}</option>
                            })}
                        </select>}
                </div>
            </div>
        </div>
    )
}