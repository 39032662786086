const updateSpamList = (state, action) => {

  if (state === undefined) {
    return {
      spam: null,
      loading: false,
      error: false,
    };
  }

  switch (action.type) {
    case 'SPAM_REQUESTED':
      return {
        spam: null,
        loading: true,
        error: false,
      };
    case 'SPAM_FETCH_SUCCESS':
      console.log(action.payload)
      return {
        spam: action.payload,
        loading: false,
        error: false,
      };
    case 'SPAM_FETCH_ERROR':
      return {
        spam: state.spamList.spam,
        loading: false,
        error: true,
      };
    default:
      return state.spamList;
  }
};

export default updateSpamList;