const schedulesRequested = () => {
    return {
        type: 'SCHEDULES_REQUESTED',
    }
}

const schedulesLoaded = (domains) => {
    return {
        type: 'SCHEDULES_FETCH_SUCCESS',
        payload: domains
    }
}

const schedulesError = (data) => {
    return {
        type: 'SCHEDULES_ERROR',
        payload: data
    }
}

const deleteSchedule = (id) => {
    return {
        type: 'TITLE_DELETE_ONE',
        payload: id
    }
}

const updateSchedule = (data) => {
    return {
        type: 'TITLE_UPDATE',
        payload: data,
    }
}

const fetchSchedules = (apiService) => () => (dispatch) => {
    dispatch(schedulesRequested)
    apiService.schedulesList()
        .then((response) => dispatch(schedulesLoaded(response.data)))
        .catch((err) => dispatch(schedulesError(err)))
}

export {
    fetchSchedules,
    deleteSchedule,
    updateSchedule
}