import { useNavigate } from "react-router-dom"

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Component, useEffect, useState } from "react";
import TableItem from "./table-item";
import PageTitle from "../../page-title";
import { Modals, showModal } from "../../modals";
import { compose } from "../../../utils";
import { deleteSchedule, updateSchedule, fetchSchedules } from "../../../actions/schedules";
import { Spinner } from "../../spinner";
import { withApiService } from "../../hoc";
import ApiService from "../../../services/api-service";

const SchedulesPage = ({schedules, deleteSchedule, updateSchedule, fetchSchedules}) => {
    const navigate= useNavigate();
    const [editSchedule, setEditSchedule] = useState({})
    const [cities, setCities] = useState([])
    const forms = [
        {
            title: 'Добавить расписание',
            name: 'addSchedule',
            url:"/api/schedule/",
            type: "put",
            action: (action) => {console.log('need to close modal'); fetchSchedules()},
            fields: [
                {
                    name: 'Город',
                    json: 'cityID',
                    type: 'select',
                    options: cities,
                },
                {
                    name: 'Проверка номера',
                    json: 'validatePhone',
                    type: 'select',
                    options: [
                        {
                            name: "Да",
                            value: true,
                        },
                        {
                            name: "Нет",
                            value: false,
                        },
                    ],
                },
                {
                    name: 'Начало (час)',
                    json: 'startHour',
                    type: 'number',
                },
                {
                    name: 'Начало (минута)',
                    json: 'startMinute',
                    type: 'number',
                },
                {
                    name: 'Конец (час)',
                    json: 'endHour',
                    type: 'number',
                },
                {
                    name: 'Конец (минута)',
                    json: 'endMinute',
                    type: 'number',
                },
            ],
        },
        {
            title: 'Редактировать расписание',
            name: 'updateSchedule',
            url: "/api/schedule/",
            type: "patch",
            action: (action) => {console.log(action); fetchSchedules()},
            edit: (action) => { setEditSchedule(action)},
            //imageurl:"public/objects/"+activeObject.ID+"/actions/", //id
            date: editSchedule,
            clear:false,
            fields: [
                {
                    name: 'Город',
                    json: 'cityID',
                    type: 'select',
                    options: cities,
                },
                {
                    name: 'Проверка номера',
                    json: 'validatePhone',
                    type: 'select',
                    options: [
                        {
                            name: "Да",
                            value: true,
                        },
                        {
                            name: "Нет",
                            value: false,
                        },
                    ],
                },
                {
                    name: 'Начало (час)',
                    json: 'startHour',
                    type: 'number',
                },
                {
                    name: 'Начало (минута)',
                    json: 'startMinute',
                    type: 'number',
                },
                {
                    name: 'Конец (час)',
                    json: 'endHour',
                    type: 'number',
                },
                {
                    name: 'Конец (минута)',
                    json: 'endMinute',
                    type: 'number',
                },
                {
                    json: 'ID',
                    type: 'hidden',
                },
            ],
        },
        {
            title: 'Удалить расписание',
            question: 'Вы действительно хотите удалить расписание?',
            name: 'deleteSchedule',
            url:"/api/schedule/"+editSchedule.ID,
            type: "delete",
            action: (action) => { console.log(action); fetchSchedules()},
            //edit: () => {},
            date: editSchedule,
            clear:false,
            fields: [
                {
                json: 'ID',
                type: 'hidden',
                },
            ]
        },
    ]

    useEffect(()=>{
        let apiService = new ApiService
        apiService.citiesList().then((response) => {
            
            let citiesList = []
            response.data.forEach((city)=> {
                citiesList.push({name: city.name, value: parseInt(city.id)})
            })
            setCities(citiesList)
        })
    },[])
    return <div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle title={"Расписание проверки номеров"} />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="" style={{ display: "flex", justifyContent: "end" }}>
                                    <div className="mb-4">
                                        <button onClick={() => { showModal('addSchedule')}} type="button" className="btn btn-primary waves-effect waves-light"><i className="feather-plus"></i> Добавить расписание</button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="row"><div className="col-sm-12 col-md-6"><div className="dataTables_length" id="DataTables_Table_0_length">
                                        <label style={{ display: "flex" }}>
                                            Show <select style={{ width: "60px", marginLeft: "10px", marginRight: "10px" }} className="custom-select custom-select-sm form-control form-control-sm form-select form-select-sm">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select> entries
                                        </label>
                                    </div>
                                    </div>
                                        <div className="col-sm-12 col-md-6">
                                            <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                                <label>Поиск:<input type="search" className="form-control form-control-sm" placeholder="" aria-controls="DataTables_Table_0" /></label>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table className="table align-middle datatable dt-responsive table-check nowrap dataTable no-footer table-striped" style={{ borderCollapse: "collapse", borderSpacing: "0px 8px", width: "100%" }} >
                                                    <thead>
                                                        <tr className="bg-transparent" role="row">
                                                            <th style={{ width: "30px" }} className="sorting sorting_asc">
                                                                <div className="form-check font-size-16">
                                                                    <input type="checkbox" name="check" className="form-check-input" id="checkAll" />
                                                                    <label className="form-check-label" htmlFor="checkAll"></label>
                                                                </div>
                                                            </th>
                                                            <th style={{ width: "60px" }} className="sorting" >ID</th>
                                                            <th style={{  }} className="sorting" >Город</th>
                                                            <th style={{  }} className="sorting" >Проверка номера</th>
                                                            <th style={{  }} className="sorting" >Время (start)</th>
                                                            <th style={{  }} className="sorting" >Время (end)</th>
                                                            <th style={{ width: "200px" }} className="sorting" >Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            schedules.map((schedule) => {
                                                                console.log(schedule)
                                                                return <TableItem key={schedule.ID} schedule={schedule} cities={cities} deleteSchedule={(e) => {e.preventDefault(); setEditSchedule(schedule); showModal("deleteSchedule")}} updateSchedule={(e) =>{ e.preventDefault(); setEditSchedule(schedule); showModal("updateSchedule")}}/>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginBottom: "50px"}}>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modals forms={forms} />
    </div>
}

class ScheduleListPageContainer extends Component {
    
    componentDidMount() {
      this.props.fetchSchedules();
    }
  
    render() {
      const { schedules, loading, error, deleteSchedule, updateSchedule, fetchSchedules } = this.props;
      if (loading || schedules === null) {
          return <Spinner />
      }
      return <SchedulesPage schedules={schedules} loading={loading} error={error} deleteSchedule={deleteSchedule} updateSchedule={updateSchedule} fetchSchedules={fetchSchedules} />;
    }
  }
  
  const mapStateToProps = ({ schedulesList: { schedules, loading, error }}) => {
    return { schedules, loading, error };
  };
  
  const mapDispatchToProps = (dispatch, { apiService}) => {
    return bindActionCreators({
        fetchSchedules: fetchSchedules(apiService),
        updateSchedule: updateSchedule,
        deleteSchedule: deleteSchedule,
    }, dispatch);
  };

  export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
  )(ScheduleListPageContainer);