import updateAuthStatus from "./updateAuthStatus";
import updateDomainDetail from "./updateDomainDetail";
import updateDomainsList from "./updateDomainsList";
import updateLeadsList from "./updateLeadsList";
import updateOrganizationsList from "./updateOrganizations";
import updateTitlesList from "./updateTitles";
import updatePricesList from "./updatePrices";
import updateUsersList from "./updateUsers";
import updateUserDetail from "./updateUserDetail";
import updateTestingsList from "./updateTestings";
import updateSchedulesList from "./updateSchedules";
import updateSpamList from "./updateSpamList";


const reducer = (state, action) => {
    return {
        authStatus: updateAuthStatus(state, action),
        domainsList: updateDomainsList(state, action),
        leadsList: updateLeadsList(state, action),
        domainDetail: updateDomainDetail(state, action),
        titlesList: updateTitlesList(state, action),
        organizationsList: updateOrganizationsList(state, action),
        pricesList: updatePricesList(state, action),
        usersList: updateUsersList(state, action),
        userDetail: updateUserDetail(state, action),
        testingsList: updateTestingsList(state, action),
        schedulesList: updateSchedulesList(state, action),
        spamList: updateSpamList(state, action),
    }
}

export default reducer;