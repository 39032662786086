import { useEffect, useState } from "react"
import { FlatType } from "../../../enums"

export const RoomsAnswer = ({ answer, index, updateAnswer, deleteAnswer }) => {
    const roomsTypes = {
        [FlatType.Studio]: "Студия",
        [FlatType.OneRoomFlat]: "Однокомнатная",
        [FlatType.TwoRoomedFlat]: "Двухкомнатная",
        [FlatType.ThreeRoomedFlat]: "Трехкомнатная",
        [FlatType.FourRoomedFlat]: "Четырехкомнатная",
        [FlatType.FiveAndMoreRoomedFlat]: "Пять и более комнат",
    }
    
    const updateTitle = (title) => {
        let newAnswer = answer
        newAnswer.title = title
        updateAnswer(index, newAnswer)
    }

    const updateParam = (value) => {
        let newAnswer = answer
        Object.keys(roomsTypes).forEach((type)=>{
            if (roomsTypes[type] === value) {
                newAnswer.param = parseInt(type)
                updateAnswer(index, newAnswer)
            }
        })
        
    }

    return <div key={index} className="column">
        <div className="row">
            <div className="col-lg-6 ml-2">
                <div className="mb-3" >
                    <label htmlFor="basicpill-lastname-input" className="form-label">Текст ответа</label>
                      <input style={{ marginLeft: "10px", marginRight: "10px" }} type="text" autoComplete="off" onChange={(e) => { updateTitle(e.target.value) }} className="form-control" name="mail" id="basicpill-servicetax-input" value={answer.title} /> 
                </div>
            </div>
            <div className="col-lg-6 ml-2">
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    <div className="mb-3" style={{width:"100%", marginRight: "10px"}}>
                        <label htmlFor="basicpill-lastname-input" className="form-label">Параметр фильтра</label>
                        <select className="form-select custom-select" id="template" onChange={(e) => updateParam(e.target.value)} name="template_id" value={roomsTypes[answer.param]}>
                            <option></option>
                            {Object.keys(roomsTypes).map((key)=>{
                                 return <option>{roomsTypes[key]}</option>
                            })}
                        </select>
                    </div>
                    <i onClick={() => { deleteAnswer(index) }} className="feather-trash" style={{ color: "red" }} />
                </div>
            </div>
        </div>
    </div>
}