import { useEffect, useState } from "react"
import { FlatType, JkClass } from "../../../enums"

export const PriceSliderAnswer = ({ answer, index, updateAnswer, deleteAnswer }) => {
    
    const updateParam = (value, min) => {
        let newAnswer = answer
        if (min) {
            answer.param[0] = parseInt(value)
        } else {
            answer.param[1] = parseInt(value)
        }

        updateAnswer(index, newAnswer)
    }

    if (!answer.param) {
        answer.param = [0,0]
    }
   

    return <div key={index} className="column">
        <div className="row">
            <div className="col-lg-6 ml-2">
                <div className="mb-3" >
                    <label htmlFor="basicpill-lastname-input" className="form-label">Цена от</label>
                      <input style={{ marginLeft: "10px", marginRight: "10px" }} type="number" autoComplete="off" onChange={(e) => { updateParam(e.target.value, true) }} className="form-control" name="mail" id="basicpill-servicetax-input" value={answer.param[0]} /> 
                </div>
            </div>
            <div className="col-lg-6 ml-2">
                <div className="mb-3" >
                    <label htmlFor="basicpill-lastname-input" className="form-label">Цена до</label>
                      <input style={{ marginLeft: "10px", marginRight: "10px" }} type="number" autoComplete="off" onChange={(e) => { updateParam(e.target.value, false) }} className="form-control" name="mail" id="basicpill-servicetax-input" value={answer.param[1]} /> 
                </div>
            </div>
        </div>
    </div>
}