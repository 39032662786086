import PageTitle from "../../../page-title"
import TableItem from "./table-item"
import { compose } from "../../../../utils";
import { withApiService } from "../../../hoc";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Component, useEffect, useRef, useState } from "react";
import { fetchUsers, } from "../../../../actions/users";
import { Spinner } from "../../../spinner";
import { useNavigate } from "react-router-dom"
import ApiService from "../../../../services/api-service";
import { toast } from "react-toastify";
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { Modals, showModal } from "../../../modals";

const UsersPage = ({ users, loading, fetchUsers, fetchMore }) => {
    const [templates, setTemplates] = useState(null)
    const [load, setLoad] = useState(false)
    const [search, setSearch] = useState("")
    const [copyID, setCopyID] = useState()
    const [cityID, setCityID] = useState("")
    const [cities, setCities] = useState([])
    const navigate = useNavigate();

    const inputChange = (event) => {
        setCityID(event.target.value)
        fetchUsers(search, "", 15, event.target.value)
    }
    useEffect(() => {
        let apiService = new ApiService
        apiService.templatesList().then((response) => {
            setTemplates(response.data)
        })
        apiService.citiesList().then((response) => {
            setCities(response.data)
        })
    }, [])

    const getTemplateById = (id) => {
        let name = ""
        if (templates != null) {
            templates.map(template => {
                if (template.ID == id) {
                    name = template.Name
                }
            });
        }
        return name
    }

    const forms = [
        {
            title: 'Добавить пользователя',
            name: 'addUser',
            url: "/api/users/",
            type: "put",
            action: (action) => {fetchUsers()},
            fields: [
                {
                    name: 'Имя',
                    json: 'name',
                    type: 'text',
                },
                {
                    name: 'Логин',
                    json: 'login',
                    type: 'text',
                },
                {
                    name: 'Пароль',
                    json: 'pass',
                    type: 'text',
                },
            ],
        },
    ]
    return (<div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle title={"Список пользователей"} />
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="" style={{ display: "flex", justifyContent: "end" }}>
                                    <div className="mb-4">
                                        <button onClick={() => { showModal('addUser') }} type="button" className="btn btn-primary waves-effect waves-light"><i className="feather-plus"></i>Добавить пользователя</button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        
                                        <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table align-middle datatable dt-responsive table-check nowrap dataTable no-footer table-striped" style={{ borderCollapse: "collapse", borderSpacing: "0px 8px", width: "100%" }} >
                                                        <thead>
                                                            <tr className="bg-transparent" role="row">
                                                                <th style={{ width: "30px" }} className="sorting sorting_asc">
                                                                    <div className="form-check
                                                        font-size-16">
                                                                        <input type="checkbox" name="check" className="form-check-input" id="checkAll" />
                                                                        <label className="form-check-label" htmlFor="checkAll"></label>
                                                                    </div>
                                                                </th>
                                                                <th style={{ width: "80px" }} className="sorting">ID</th>
                                                                <th style={{ width: "80px" }} className="sorting">ФИО</th>
                                                                <th style={{ width: "80px" }} className="sorting">Логин</th>
                                                                <th style={{ width: "80px" }} className="sorting">Роль</th>
                                                                {/*<th style={{ width: "80px" }} className="sorting" >Действия</th>*/}
                                                            </tr>
                                                        </thead>
                                                        {users == null ? <tbody></tbody> : <tbody>
                                                            {
                                                                users.map((user) => {
                                                                    console.log(user)
                                                                    return <TableItem user={user} fetchUsers={fetchUsers} setCopyID={setCopyID} />
                                                                })
                                                            }
                                                        </tbody>}
                                                    </table>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modals forms={forms} />
    </div>
    )
}


class UserListPageContainer extends Component {

    componentDidMount() {
        this.props.fetchUsers("", "", 15, "");
    }

    render() {
        const { users, loading, error, fetchUsers } = this.props;
        if (loading) {
            return <Spinner />
        }
        return <UsersPage users={users} loading={loading} error={error} fetchUsers={fetchUsers} />;
    }
}

const mapStateToProps = ({ usersList: { users, loading, error } }) => {
    return { users, loading, error };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return bindActionCreators({
        fetchUsers: fetchUsers(apiService),
    }, dispatch);
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(UserListPageContainer);