import React, { useEffect, useState } from "react"

export const RaionAnswer = ({ answer, index, raions, updateAnswer, deleteAnswer }) => {
    //TODO - add raions list handler to backend, and change values to real raions
    const [raionsL, setRaionsL] = useState([])
    const updateAnswersRaion = (code) => {
        let newAnswer = answer
        newAnswer.param = code
        raions.forEach(raion => {
            if (raion.code == code) {
                newAnswer.title = raion.name
            }
        });
        updateAnswer(index, newAnswer)
    }


    return <div key={index} className="column">
        <div className="row">
            <div className="col-lg-6 ml-2">
                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    <div className="mb-3" style={{width:"100%", marginRight: "10px"}}>
                        <label htmlFor="basicpill-lastname-input" className="form-label">Выберите район</label>
                        
                        <select className="form-select custom-select" id="template" onChange={(e) => {console.log(e.target.value); updateAnswersRaion(e.target.value)}} name="template_id" value={answer.param}>
                            <option></option>
                            {raions.map((raion)=>{
                                 return <option value={raion.code}>{raion.name}</option>
                            })}
                            <option value={answer.param}>{answer.title}</option>
                        </select>
                    </div>
                    <i onClick={() => { deleteAnswer(index) }} className="feather-trash" style={{ color: "red" }} />
                </div>
            </div>
           
        </div>
    </div>
}