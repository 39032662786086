const updateUserDetail = (state, action) => {

    if (state === undefined) {
      return {
        user: null,
        permissions: null,
        loading: false,
        error: false,
      };
    }
    switch (action.type) {
      case 'USER_REQUESTED':
        return {
          user: null,
          permissions: null,
          loading: true,
          error: false,
      };
      case 'PERMISSIONS_REQUESTED':
        return {
          user: state.userDetail.user,
          permissions: state.userDetail.permissions,
          loading: true,
          error: false,
      };
      case 'USER_FETCH_SUCCESS':
        return {
          user: action.payload,
          permissions: state.userDetail.permissions,
          loading: false,
          error: false,
      };
      case 'PERMISSIONS_FETCH_SUCCESS':
        return {
          user: state.userDetail.user,
          permissions: action.payload,
          loading: true,
          error: false,
      };
      case 'PERMISSIONS_FETCH_ERROR':
        return {
          user: state.userDetail.user,
          permissions: null,
          loading: true,
          error: false,
      };
      case 'USER_FETCH_ERROR':
        return {
          user: state.userDetail.user,
          permissions: state.userDetail.permissions,
          loading: false,
          error: true,
      };
      case 'USER_UPDATE_SUCCESS':
        return {
          user: action.payload,
          permissions: state.userDetail.permissions,
          loading: false,
          error: false,
      };
      case 'ADD_CITY_TO_PERMISSIONS':
        let permissions = state.userDetail.permissions
        permissions.cities_list.push(action.payload)
        return {
          user: state.userDetail.user,
          permissions: permissions,
          loading: false,
          error: false,
        };
      case 'REMOVE_CITY_PERMISSIONS':
        let remCity = state.userDetail.permissions
        remCity.cities_list = remCity.cities_list.filter((item) => item != action.payload)
        return {
          user: state.userDetail.user,
          permissions: remCity,
          loading: false,
          error: false,
        };
      case 'ADD_DOMAIN_TO_PERMISSIONS':
        let tmp = state.userDetail.permissions
        tmp.ids_list.push(action.payload)
        return {
          user: state.userDetail.user,
          permissions: tmp,
          loading: false,
          error: false,
        };
      case 'REMOVE_DOMAIN_PERMISSIONS':
        let remDomain = state.userDetail.permissions
        remDomain.ids_list = remDomain.ids_list.filter((item) => item != action.payload)
        console.log(remDomain.ids_list)
        return {
          user: state.userDetail.user,
          permissions: remDomain,
          loading: false,
          error: false,
        };
      default:
        return state.userDetail;
    }
  };
  
  export default updateUserDetail;