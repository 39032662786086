import PageTitle from "../../../page-title"
import TableItem from "./table-item"
import { compose } from "../../../../utils";
import { withApiService } from "../../../hoc";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Component, useEffect, useRef, useState } from "react";
import { fetchLeads, fetchMoreLeads } from "../../../../actions/leads";
import { Spinner } from "../../../spinner";
import { useNavigate } from "react-router-dom"
import ApiService from "../../../../services/api-service";
import { toast } from "react-toastify";
import { BottomScrollListener } from 'react-bottom-scroll-listener';

const LeadsPage = ({ leads, loading, cursor, fetchLeads, fetchMoreLeads }) => {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false)
    const [dateStart, setDateStart] = useState("1990-20-11")
    const [dateEnd, setDateEnd] = useState("1990-20-11")
    const [cityID, setCityID] = useState(0)
    const [cities, setCities] = useState([])
    useEffect(() => {
    }, [])

    const onBottom = () => {
        if (load === false && loading === false) {
            setLoad(true)
            fetchMoreLeads("", cursor, 15)
            setTimeout(() => {
                setLoad(false)
            }, 1000)
        }

    };

    const searchLeads = (event) => {
        //console.log(event.target.value)
        fetchLeads(event.target.value, "", 15)
    }

    const inputChange = (event) => {
        setCityID(event.target.value)
    }

    const dateChange = (event) => {
        if (event.target.name === "start") {
            setDateStart(event.target.value)
        } else {
            setDateEnd(event.target.value)
        }
    }
    const generateReport = () => {
        let data = {
            city_id: parseInt(cityID),
            start_date: dateStart,
            end_date: dateEnd,
        }
        console.log(data)

        fetch('/api/lead/report', { method: 'POST', body: JSON.stringify(data) })
            .then((response) => {
                return response.blob();
            })
            .then((blob) => {
                let filename = "report.xlsx";
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename; 
                document.body.appendChild(link); 
                link.click(); 
                document.body.removeChild(link);
            });
    }

    useEffect(() => {
        let dateNow = new Date()
        setDateStart(dateNow.toISOString().split('T')[0])
        dateNow.setDate(dateNow.getDate() + 1)
        setDateEnd(dateNow.toISOString().split('T')[0])
        let apiService = new ApiService
        apiService.citiesList().then((response) => {
            setCities(response.data)
        })
    }, [])
    return (<div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                <PageTitle title={"Список лидов"} />
                <BottomScrollListener offset={20} onBottom={onBottom} >
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <h5>Генерация отчета</h5>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4">
                                                    <label htmlFor="basicpill-pancard-input" className="form-label">Город</label>
                                                    <select onChange={inputChange} className="form-select custom-select" id="template" name="city_id">
                                                        <option></option>
                                                        {cities.map((city) => {
                                                            return <option value={city.id}>{city.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    <label>Дата ОТ:</label><input onChange={dateChange} type="date" className="form-control" name={"start"} placeholder="" value={dateStart} aria-controls="DataTables_Table_1" />
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    <label>Дата ДО:</label><input onChange={dateChange} type="date" className="form-control" name={"end"} placeholder="" value={dateEnd} aria-controls="DataTables_Table_2" />
                                                </div>
                                                <div className="col-sm-12 col-md-2" style={{ display: "flex" }}>
                                                    <button className="btn btn-primary" onClick={(event) => { event.preventDefault(); generateReport() }}>Сформировать</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">

                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                                        <label>Поиск:<input onChange={searchLeads} type="search" className="form-control form-control-sm" placeholder="" aria-controls="DataTables_Table_0" /></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col-sm-12">
                                                    <table className="table align-middle datatable dt-responsive table-check nowrap dataTable no-footer table-striped" style={{ borderCollapse: "collapse", borderSpacing: "0px 8px", width: "100%" }} >
                                                        <thead>
                                                            <tr className="bg-transparent" role="row">
                                                                <th style={{ width: "30px" }} className="sorting sorting_asc">
                                                                    <div className="form-check
                                                        font-size-16">
                                                                        <input type="checkbox" name="check" className="form-check-input" id="checkAll" />
                                                                        <label className="form-check-label" htmlFor="checkAll"></label>
                                                                    </div>
                                                                </th>
                                                                <th style={{ width: "80px" }} className="sorting" >Номер телефона</th>
                                                                <th style={{ width: "80px" }} className="sorting" >Имя</th>
                                                                <th style={{ width: "80px" }} className="sorting" >Город</th>
                                                                <th style={{ width: "80px" }} className="sorting" >Сайт</th>
                                                                <th style={{ width: "80px" }} className="sorting" >Дата</th>
                                                                <th style={{ width: "80px" }} className="sorting" >utm_source</th>
                                                                <th style={{ width: "80px" }} className="sorting" >Действия</th>
                                                            </tr>
                                                        </thead>
                                                        {leads == null ? <tbody></tbody> : <tbody>
                                                            {
                                                                leads.map((lead) => {
                                                                    return <TableItem lead={lead} fetchLeads={fetchLeads} />
                                                                })
                                                            }
                                                        </tbody>}
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ BottomScrollListener>
            </div>
        </div>

    </div>
    )
}


class DomainListPageContainer extends Component {

    componentDidMount() {
        this.props.fetchLeads("", "", 15);
    }

    render() {
        const { leads, cursor, loading, error, fetchLeads, fetchMoreLeads } = this.props;
        if (loading) {
            return <Spinner />
        }
        return <LeadsPage leads={leads} cursor={cursor} loading={loading} error={error} fetchLeads={fetchLeads} fetchMoreLeads={fetchMoreLeads} />;
    }
}

const mapStateToProps = ({ leadsList: { leads, cursor, loading, error } }) => {
    return { leads, cursor, loading, error };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return bindActionCreators({
        fetchLeads: fetchLeads(apiService),
        fetchMoreLeads: fetchMoreLeads(apiService)
    }, dispatch);
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(DomainListPageContainer);