import Cookies from "js-cookie";
import {ShoppingCart} from "react-feather"

const getRole = () =>  {
    let token = Cookies.get('payload')
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    let payload = JSON.parse(jsonPayload)

    return payload.user_role;
};

let elemsFull = [
    {
        name: 'Домены',
        icon:  <i className="feather-globe" />,
        status: false,
        url: "/",
        children: []
    },
    {
        name: 'Лиды',
        icon: <i className="feather-dollar-sign" />,
        status: false,
        url: "/leads",
        children: []
    },
    {
        name: 'Подмены',  
        icon: <i className="feather-repeat" />,
        status: false,
        url: "/titles",
        children: []
    },
    {
        name: 'Цены по городам',  
        icon: <i className="feather-dollar-sign" />,
        status: false,
        url: "/prices",
        children: []
    },
    {
        name: 'Портал',
        icon: <i className="feather-at-sign" />,
        status: false,
        url: "/portal",
        children: [
            {
                name: 'Города',
                status: false,
                url: "/portal/cities", //ClientsAll
            },
            {
                name: 'Локации',
                status: false,
                url: "/portal/locations", //ClientsAll
            },
        ]
    },
    {
        name: 'Пользователи',
        icon: <i className="feather-user" />,
        status: false,
        url: "/users",
        children: []
    },
    {
        name: 'A/B Тестирование',
        icon: <i className="feather-clipboard" />,
        status: false,
        url: "/testing",
        children: []
    },
    {
        name: 'Организации',
        icon: <i className="feather-command" />,
        status: false,
        url: "/organizations",
        children: []
    },
    {
        name: 'Расписания',
        icon: <i className="feather-clock" />,
        status: false,
        url: "/schedules",
        children: []
    },
    {
        name: 'Проверка номеров',
        icon: <i className="feather-phone" />,
        status: false,
        url: "/phone-numbers",
        children: []
    },
    {
        name: 'Спам',
        icon: <i className="feather-alert-triangle" />,
        status: false,
        url: "/spam",
        children: []
    },
    {
        name: 'Спам-проверка номеров',
        icon: <i className="feather-user-x" />,
        status: false,
        url: "/spam-validate",
        children: []
    },
    {
        name: 'Выход',
        icon: <i className="feather-lock" />,
        status: false,
        url: "/log-out",
        children: []
    },
]

let elemsSimple = [
    {
        name: 'Домены',
        icon:  <i className="feather-globe" />,
        status: false,
        url: "/",
        children: []
    },
    {
        name: 'A/B Тестирование',
        icon: <i className="feather-clipboard" />,
        status: false,
        url: "/testing",
        children: []
    },
    {
        name: 'Подмены',  
        icon: <i className="feather-repeat" />,
        status: false,
        url: "/titles",
        children: []
    },
    {
        name: 'Цены по городам',  
        icon: <i className="feather-dollar-sign" />,
        status: false,
        url: "/prices",
        children: []
    },
    {
        name: 'Организации',
        icon: <i className="feather-command" />,
        status: false,
        url: "/organizations",
        children: []
    },
    {
        name: 'Выход',
        icon: <i className="feather-lock" />,
        status: false,
        url: "/log-out",
        children: []
    },
]

const getMenu = () => {
    return getRole() == 'admin'? elemsFull : elemsSimple;
}

export default getMenu